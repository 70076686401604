import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";

/* 
 * post comments structure
 * [id]: {
 *   comments: [...],
 *   loading: true/false,
 *   totalComments,
 * }
 *  */
export default {
  // ...Pagination("comments").model(),
  // ...CRUD("comments").model(),
  posts: {},
};
