import axios from "axios";
import StorageManager from "../helpers/StorageManager";
import { toast } from "react-toastify";
import { isArray } from "lodash";
import { hardAuth } from "../config";
import { node } from "prop-types";

const baseURL = process.env.REACT_APP_API_GATEWAY_URL;

export const parseError = (error) => {
  console.log(error);
  if (!error) return {};

  if (!error.response || !error.response.data) {
    return {
      status: 400,
      message: "Something went wrong",
    };
  }

  let errorData = error.response.data;
  errorData = typeof errorData === "string" ? {} : errorData;

  const {
    field,
    message = "Something went wrong",
    statusCode: status = 400,
    errors,
    ...data
  } = errorData;

  let validation = {};
  if (status === 422 || status === 409) {
    if (status === 409) {
      validation = {
        validation: [{ field, message: `${field} already exists` }],
      };
    } else {
      const errorArray = errors && isArray(errors) ? errors : [];

      validation = {
        validation: errorArray.map(({ field, constraints }) => ({
          field,
          message: Object.values(constraints)[0],
        })),
      };
    }
  }

  return {
    ...data,
    ...validation,
    status,
    message,
  };
};

class BaseService {
  constructor() {
    this.token = StorageManager.get("token");

    this.api = axios.create({
      baseURL,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

    this.api.interceptors.request.use((config) => {
      const token = StorageManager.get("token");
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });

    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          process.env.NODE_ENV === "development" &&
          ((error && error.status === 500) ||
            (error && error.response.status === 500))
        ) {
          console.dir(error);
          toast.error(parseError(error).message);
        }

        if (
          error.response &&
          error.response.status === 401 &&
          window.location.pathname !== "/" &&
          !hardAuth
        ) {
          if (process.env.NODE_ENV === "development")
            console.log("Session expired, please login again");
          else toast.error("Session expired, please login again");

          StorageManager.clearOne("token");
          window.location.href = "/";
        }

        return Promise.reject(parseError(error));
      }
    );
  }

  apiCall(method, { url, data }) {
    return this.api[method](url, data);
  }

  apiPost(options) {
    return this.apiCall("post", options);
  }

  apiPostWithFormData({ url, data, ...rest }) {
    return this.apiCall("post", {
      url, 
      data,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      ...rest,
    });
  }

  apiGet(options) {
    return this.apiCall("get", options);
  }

  apiGetWithFilters({ url, data }, filters = {}) {
    const params = Object.keys(filters).reduce((p, c) => {
      let value = filters[c];
      let name = c;

      if (value && typeof value === "object" && !Array.isArray(value)) {
        const { mapValue = (v) => v, value: v, name: n = name } = value;
        value = mapValue(v);
        name = n;
      }

      p[name] = value;
      return p;
    }, {});

    return this.apiCall("get", { url, data: { ...data, params } });
  }

  apiDelete(options) {
    return this.apiCall("delete", options);
  }

  apiPut(options) {
    return this.apiCall("put", options);
  }

  assignParamsToUrl(path, params) {
    let paramsPath = path;
    for (let param of path.match(/\{\w+\}/gm)) {
      const key = param.match(/\w+/)[0];
      paramsPath = paramsPath.replace(/\{\w+\}/, params[key]);
    }
    return paramsPath;
  }
}

export default BaseService;
