import initialState from "../models/paginated";
import { createReducer, createActions } from "reduxsauce";

const { Types: types, Creators: actions } = createActions({
  requestFetchPaginated: ["value"],
  fetchPaginatedSucceeded: null,
  fetchPaginatedFailed: null,
});

const handleEntityState = (state, action) => {
  const entity = action.value ? action.value.entity : "";
  const entityState = entity ? state[entity] : {};

  return {
    entity,
    entityState,
    setState: (newState) => (entity ? { ...state, [entity]: newState } : state),
  };
};

export default createReducer(initialState, {
  [types.REQUEST_FETCH_PAGINATED]: (state = initialState, action) => {
    const { entityState, setState } = handleEntityState(state, action);
    return setState({
      ...entityState,
      loading: true,
      page: entityState.page + 1,
      calling: true,
    });
  },
  [types.FETCH_PAGINATED_SUCCEEDED]: (state = initialState, action) => {
    const { entityState, entity, setState } = handleEntityState(state, action);
    return setState({
      ...entityState,
      [entity]: [...entityState[entity], ...action.value.paginated],
      loading:
        action.value.done !== undefined
          ? !action.value.done
          : action.value.paginated.length === 10,
      calling: false,
    });
  },
  [types.FETCH_PAGINATED_FAILED]: (state = initialState, action) => {
    const { entityState, setState } = handleEntityState(state, action);
    return setState({
      ...entityState,
      page: entityState.page - 1,
      done: true,
      error: true,
      calling: false,
    });
  },
});

export { types, actions };
