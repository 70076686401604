import { types } from "../../../reducers/schedule";
import { fetchEvents, getFilters } from ".";
import { FILTERS } from "../../../../components/Scheduler/constants";
import { getDateKey } from "../../../../helpers/Events";

const moment = require("moment");
const { select, put, call, all } = require("redux-saga/effects");

export function* fetchMonthEvents(state) {
  const { date, filters } = state;

  const authenticatedUser = yield select(
    ({ authentication: { user: authenticatedUser } }) => authenticatedUser
  );

  const numberOfWeeks = moment(date).startOf("month").day() > 4 ? 6 : 5;
  const startOfRange = moment(date).startOf("month").startOf("week");
  const dates = new Array(7 * numberOfWeeks)
    .fill(null)
    .map((_, i) => moment(startOfRange).add(i, "days"));

  yield put({ type: types.INITIALIZE_MONTH_EVENTS, dates });

  try {
    const tasks = dates.map((date) =>
      call(fetchEvents, {
        ...getFilters(
          {
            ...filters,
            user: filters[FILTERS.IS_SELF]
              ? authenticatedUser
              : filters[FILTERS.USER],
            from: moment(date).startOf("day"),
            to: moment(date).endOf("day"),
          },
          "event"
        ),
        perPage: 4,
        page: 1,
      })
    );

    const extras = {};
    let events = yield all(tasks);

    events = events.reduce((list, { events, total, date }) => {
      const newList = list.concat(events);

      if (total - events.length > 0)
        extras[getDateKey(date)] = total - events.length;

      return newList;
    }, []);

    yield put({ type: types.FETCH_MONTH_EVENTS_SUCCEEDED, events, extras });
  } catch (error) {
    console.log(error);
    yield put({ type: types.FETCH_MONTH_EVENTS_FAILED, error });
  }
}
