import paginated from "./reducers/paginated";
import authentication from "./reducers/authentication";
import forms from "./reducers/forms";
import schedule from "./reducers/schedule";
import profile from "./reducers/profile";
import people from "./reducers/people";
import locationGroups from "./reducers/locationGroups";
import locations from "./reducers/locations";
import jobGroups from "./reducers/jobGroups";
import jobs from "./reducers/jobs";
import employeeTypes from "./reducers/employeeTypes";
import payGroups from "./reducers/payGroups";
import orgLevels from "./reducers/orgLevels";
import orgLevelsRecords from "./reducers/orgLevelsRecords";
import breakRules from "./reducers/breakRules";
import eventTypes from "./reducers/eventTypes";
import locales from "./reducers/locales";
import overtime from "./reducers/overtime";
import userRoles from "./reducers/userRoles";
import userGroups from "./reducers/userGroups";
import earningCodes from "./reducers/earningCodes";
import leaves from "./reducers/leaves";
import notifications from "./reducers/notifications";
import posts from "./reducers/posts";
import comments from "./reducers/comments";
import budgets from "./reducers/budgets";
import messages from "./reducers/messages";
import conversations from "./reducers/conversations";
import leaveTypes from "./reducers/leaveTypes";

export default {
  paginated,
  authentication,
  forms,
  schedule,
  profile,
  people,
  locationGroups,
  locations,
  jobGroups,
  jobs,
  employeeTypes,
  payGroups,
  orgLevels,
  orgLevelsRecords,
  breakRules,
  eventTypes,
  locales,
  overtime,
  userRoles,
  userGroups,
  earningCodes,
  leaves,
  notifications,
  posts,
  comments,
  budgets,
  messages,
  conversations,
  leaveTypes,
};
