import moment from "moment";
import { call, put, select } from "redux-saga/effects";
import { FILTERS } from "../../../../components/Scheduler/constants";
import { schedulerModes } from "../../../../config";
import { mapReceivedJobsAndLocations } from "../../../../helpers/Users";
import UserService from "../../../../services/UserService";
import { types } from "../../../reducers/schedule";
import { fetchEvents, getFilters } from ".";

export function* fetchUserEvents(state, action) {
  const { filters: stateFilters, mode, date, selectedDate } = state;
  const { value } = action;

  const { page: oldPage, users: oldUsers, authenticatedUser } = yield select(
    ({
      authentication: { user: authenticatedUser },
      schedule: {
        userEvents: { users, page },
      },
    }) => ({
      authenticatedUser,
      users,
      page,
    })
  );

  const page = value?.page || oldPage;

  let from = [schedulerModes.DAY, schedulerModes.WEEK].includes(mode)
    ? date
    : selectedDate;

  if (!from) {
    yield put({
      type: types.FETCH_EVENTS_FAILED,
      mapping: "user",
      error: { message: "No date set" },
    });
    return;
  } else {
    from = moment(from).startOf("day");

    yield put({ type: types.START_FETCHING_EVENTS, mapping: "user" });
  }

  let users,
    events = [],
    total;

  if (value && page === oldPage) {
    users = oldUsers;
  } else if (stateFilters[FILTERS.USER] || stateFilters[FILTERS.IS_SELF]) {
    const filters = {
      page,
      perPage: 10,
      ...getFilters(
        {
          ...stateFilters,
          from,
          to: moment(from).endOf(mode === schedulerModes.DAY ? "day" : "week"),
        },
        "event"
      ),
    };

    if (stateFilters[FILTERS.IS_SELF]) {
      filters[FILTERS.USER] = authenticatedUser.id;
    }

    users = stateFilters[FILTERS.IS_SELF]
      ? [authenticatedUser]
      : [stateFilters[FILTERS.USER]];

    try {
      const res = yield call(fetchEvents, filters);
      total = res.total;
      events = res.events;
    } catch (error) {
      console.log(error);

      yield put({
        type: types.FETCH_USER_EVENTS_FAILED,
        error,
      });
    }
  } else {
    const filters = {
      page,
      perPage: 10,
      ...getFilters(
        {
          ...stateFilters,
          from,
          to: moment(from).endOf(mode === schedulerModes.DAY ? "day" : "week"),
        },
        "user"
      ),
    };

    try {
      const res = yield call(UserService.getWithEvents, filters);

      const { result, totalItems } = res.data;

      total = totalItems;

      users = result.map(
        ({ locations, jobs, events: currentUserEvents, ...rest }) => {
          const user = {
            ...rest,
            jobs: mapReceivedJobsAndLocations(jobs),
            locations: mapReceivedJobsAndLocations(locations),
          };

          const formatedUserEvents = currentUserEvents.map((event) => ({
            ...event,
            user,
          }));

          events = events.concat(formatedUserEvents);

          return user;
        }
      );

      console.log({ users, events });
    } catch (error) {
      console.log(error);

      yield put({
        type: types.FETCH_USER_EVENTS_FAILED,
        error,
      });
    }
  }

  yield put({
    type: types.FETCH_USER_EVENTS_SUCCEEDED,
    dates:
      mode === schedulerModes.WEEK
        ? new Array(7).fill(null).map((_, i) => moment(from).add(i, "days"))
        : [from],
    users,
    events,
    total,
  });
}
