import BaseService from "./BaseService";

class CRUDService extends BaseService {
  entity = "";
  parentEntity = "";
  withFilters = false;
  isDeleteDeactivate = true;

  getUrl = (id) => {
    return `${this.parentEntity ? `/${this.parentEntity}` : ""}/${this.entity}${
      id ? `/${id}` : ""
    }`;
  };

  getAll = async (filters) =>
    this.withFilters
      ? this.apiGetWithFilters({ url: this.getUrl() }, filters)
      : this.apiGet({ url: this.getUrl() });

  getOne = (id) => {
    return this.apiGet({ url: this.getUrl(id) });
  };

  updateOne = async (id, data) =>
    this.apiPut({
      url: `${this.getUrl()}/${id}`,
      data,
    });

  create = async (data) =>
    this.apiPost({
      url: this.getUrl(),
      data,
    });

  deleteOne = async (id, data) =>
    this.isDeleteDeactivate
      ? this.apiPut({
          url: `${this.getUrl()}/${id}`,
          data: {
            ...data,
          },
        })
      : this.apiDelete({ url: `${this.getUrl()}/${id}` });
}

export default CRUDService;
