import { takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import BudgetsService from "../../services/BudgetsService";

export default function* () {
  yield takeEvery(
    Pagination("budgets").types().request,
    Pagination("budgets").saga(BudgetsService.getAll)
  );
  yield takeEvery(
    CRUD("budgets").types().submit.request,
    CRUD("budgets").saga(BudgetsService).submit()
  );
  yield takeEvery(
    CRUD("budgets").types().delete.request,
    CRUD("budgets").saga(BudgetsService).delete()
  );
}
