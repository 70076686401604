import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import Button from "../Button";

const clipDistance = 10;

function ContextMenu({
  cancel,
  config,
  elementId,
  onOpen: onOpenParent,
  onClose: onCloseParent,
}) {
  const menuRef = useRef();
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const onOpen = useCallback(onOpenParent ? onOpenParent : () => {}, [
    onOpenParent,
  ]);
  const onClose = useCallback(onCloseParent ? onCloseParent : () => {}, [
    onCloseParent,
  ]);

  const onContextMenu = useCallback(
    (e) => {
      e.preventDefault();

      if (e.target.closest(cancel)) {
        setOpen(false);
        return;
      }

      const { pageX: x, pageY: y } = e;

      setPosition({ x, y });
      setOpen(true);
      onOpen(e);
    },
    [onOpen, cancel]
  );

  const clickOutsideHandler = useCallback(
    (e) => {
      if (
        open &&
        ((e.type === "contextmenu" && !e.target.closest(`#${elementId}`)) ||
          (e.type === "click" && !e.target.closest(".context-menu")))
      ) {
        setOpen(false);
        onClose();
      }
    },
    [elementId, open, onClose]
  );

  useEffect(() => {
    const element = document.getElementById(elementId);

    if (!element) return;

    element.addEventListener("contextmenu", onContextMenu);
    document.addEventListener("click", clickOutsideHandler);
    document.addEventListener("contextmenu", clickOutsideHandler);

    return () => {
      element.removeEventListener("contextmenu", onContextMenu);
      document.removeEventListener("click", clickOutsideHandler);
      document.removeEventListener("contextmenu", clickOutsideHandler);
    };
  }, [onContextMenu, clickOutsideHandler, elementId]);

  useEffect(() => {
    if (!open) return;

    const heightThreshold = window.innerHeight;
    const widthThreshold = window.innerWidth;
    const width = menuRef.current.offsetWidth;
    const height = menuRef.current.offsetHeight;

    let x = position.x,
      y = position.y;

    if (x + width > widthThreshold - clipDistance)
      x = widthThreshold - width - clipDistance;
    if (y + height > heightThreshold - clipDistance)
      y = heightThreshold - height - clipDistance;

    if (x !== position.x || y !== position.y) setPosition({ x, y });
  }, [clickOutsideHandler, open, position]);

  const children = useMemo(() => {
    return config.map(
      (
        {
          label,
          autoClose = true,
          onClick = () => {},
          showIf = () => true,
          ...rest
        },
        i
      ) =>
        showIf() && (
          <Button
            key={i}
            onClick={(e) => {
              onClick(e, () => setOpen(false));
              if (autoClose) setOpen(false);
            }}
            {...rest}
          >
            {label}
          </Button>
        )
    );
  }, [config]);

  const menu = useMemo(() => {
    const { x, y } = position;
    return (
      <div
        ref={menuRef}
        style={{ transform: `translate(${x}px, ${y}px)` }}
        className="context-menu"
      >
        {children}
      </div>
    );
  }, [children, position]);

  return open
    ? ReactDOM.createPortal(
        menu,
        document.getElementById("context-menu-portal")
      )
    : null;
}

export const ContextMenuPortal = () => <div id="context-menu-portal" />;

export default ContextMenu;
