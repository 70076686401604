import { all, fork } from "redux-saga/effects";
import paginated from "./sagas/paginated";
import authentication from "./sagas/authentication";
import forms from "./sagas/forms";
import schedule from "./sagas/schedule";
import profile from "./sagas/profile";
import people from "./sagas/people";
import locationGroups from "./sagas/locationGroups";
import locations from "./sagas/locations";
import jobGroups from "./sagas/jobGroups";
import jobs from "./sagas/jobs";
import employeeTypes from "./sagas/employeeTypes";
import payGroups from "./sagas/payGroups";
import orgLevels from "./sagas/orgLevels";
import orgLevelsRecords from "./sagas/orgLevelsRecords";
import breakRules from "./sagas/breakRules";
import eventTypes from "./sagas/eventTypes";
import locales from "./sagas/locales";
import overtime from "./sagas/overtime";
import userRoles from "./sagas/userRoles";
import userGroups from "./sagas/userGroups";
import earningCodes from "./sagas/earningCodes";
import leaves from "./sagas/leaves";
import notifications from "./sagas/notifications";
import posts from "./sagas/posts";
import comments from "./sagas/comments";
import budgets from "./sagas/budgets";
import messages from "./sagas/messages";
import conversations from "./sagas/conversations";
import leaveTypes from "./sagas/leaveTypes";

function* rootSaga() {
  yield all([
    fork(paginated),
    fork(authentication),
    fork(profile),
    fork(forms),
    fork(schedule),
    fork(locationGroups),
    fork(locations),
    fork(people),
    fork(jobGroups),
    fork(jobs),
    fork(employeeTypes),
    fork(payGroups),
    fork(orgLevels),
    fork(orgLevelsRecords),
    fork(breakRules),
    fork(eventTypes),
    fork(locales),
    fork(overtime),
    fork(userRoles),
    fork(userGroups),
    fork(earningCodes),
    fork(leaves),
    fork(notifications),
    fork(posts),
    fork(comments),
    fork(budgets),
    fork(messages),
    fork(conversations),
    fork(leaveTypes),
  ]);
}

export default rootSaga;
