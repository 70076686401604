import moment from "moment";
import { schedulerModes } from "../../../config";
import { FILTERS } from "../../../components/Scheduler/constants";
import initialState from "../../models/schedule";

export const actions = {
  // filters
  changeDate: ["date"],
  changeMode: ["mode"],
  selectDate: ["selectedDate"],
  toggleFilters: ["open"],
  addFilter: ["filter"],
  removeFilter: ["filter"],
  updateFilters: ["filters"],
  clearFilters: null,
  changeSort: ["field"],
};

export default (types) => ({
  [types.CHANGE_DATE]: (state, { date }) => ({
    ...state,
    date: moment(date),
    selectedDate: undefined,
  }),
  [types.CHANGE_MODE]: (state, { mode }) => ({
    ...state,
    mode,
    selectedDate: undefined,
    date:
      mode === schedulerModes.DAY
        ? moment().startOf("day")
        : mode === schedulerModes.WEEK
        ? moment().startOf("week")
        : moment().startOf("month"),
  }),
  [types.SELECT_DATE]: (state, { selectedDate }) => ({
    ...state,
    selectedDate,
  }),
  [types.TOGGLE_FILTERS]: ({ filtersPanel, ...state }, { open }) => ({
    ...state,
    filtersPanel: {
      ...filtersPanel,
      open: open !== undefined ? open : !filtersPanel.open,
    },
  }),
  [types.UPDATE_FILTERS]: (
    { filtersPanel: { filters: oldFilters, ...filtersPanel }, ...state },
    { filters }
  ) => ({
    ...state,
    filtersPanel: {
      ...filtersPanel,
      filters: {
        ...oldFilters,
        ...filters,
      },
    },
  }),
  [types.ADD_FILTER]: (
    { filtersPanel: { filters: oldFilters, ...filtersPanel }, ...state },
    { filter }
  ) => ({
    ...state,
    filtersPanel: {
      ...filtersPanel,
      filters: {
        ...oldFilters,
        ...filter,
      },
    },
  }),
  [types.REMOVE_FILTER]: ({ filtersPanel, ...state }, { filter }) => {
    const newFilters = { ...filtersPanel.filters };
    if (newFilters.hasOwnProperty(filter)) delete newFilters[filter];

    return {
      ...state,
      filtersPanel: {
        ...filtersPanel,
        filters: newFilters,
      },
    };
  },
  [types.CLEAR_FILTERS]: (state) => ({
    ...state,
    filtersPanel: {
      open: false,
      filters: { ...initialState.filtersPanel.filters },
    },
  }),
  [types.CHANGE_SORT]: ({ filtersPanel, ...rest }, { field }) => {
    const filters = { ...filtersPanel.filters };

    if (filters.sortBy.field === field) {
      filters.sortBy.order = filters.sortBy.order === "desc" ? "asc" : "desc";
    } else filters.sortBy = { field, order: "asc" };

    return {
      ...rest,
      filtersPanel: {
        ...filtersPanel,
        filters,
      },
    };
  },
});
