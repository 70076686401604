import { takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import OvertimeService from "../../services/OvertimeService";

export default function* () {
  yield takeEvery(
    Pagination("overtime").types().request,
    Pagination("overtime").saga(OvertimeService.getAll)
  );
  yield takeEvery(
    CRUD("overtime").types().submit.request,
    CRUD("overtime")
      .saga(OvertimeService)
      .submit({ 
        occurrence: ({ value }) => value,
        status: (value) => Boolean(value),
      })
  );
  yield takeEvery(
    CRUD("overtime").types().delete.request,
    CRUD("overtime").saga(OvertimeService).delete()
  );
}
