import { createReducer, createActions } from "reduxsauce";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";

const initialState = {
  ...Pagination("userRoles").model(),
  ...CRUD("userRoles").model(),
};

const { Types: types, Creators: actions } = createActions({
  ...Pagination("userRoles").actions(),
  ...CRUD("userRoles").actions(),
});

export default createReducer(initialState, {
  ...Pagination("userRoles").reducer(),
  ...CRUD("userRoles").reducer(),
});

export { types, actions };
