import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
// import { fab } from "@fortawesome/free-brands-svg-icons";
import clsx from "clsx";

export const init = () => library.add(fas, far);

const Icon = ({ onClick, icon, className, ...props }) => {
  const iconElement = (
    <FontAwesomeIcon
      icon={icon}
      {...(onClick ? {} : props)}
      className={clsx("icon", !onClick && className)}
    />
  );

  return onClick ? (
    <div
      className={clsx("button-icon icon", className)}
      {...props}
      onClick={onClick}
    >
      {iconElement}
    </div>
  ) : (
    iconElement
  );
};

export default Icon;
