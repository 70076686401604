import CRUDService from "./CRUDService";

class LevelRecordService extends CRUDService {
  getAll = async (group, filters) =>
    await this.apiGetWithFilters({ url: `/${group}/org-levels` }, filters);

  updateOne = async (group, id, data) =>
    await this.apiPut({
      url: `/${group}/org-levels/` + id,
      data,
    });

  create = async (group, data) =>
    await this.apiPost({
      url: `/${group}/org-levels`,
      data,
    });

  deleteOne = async (group, id, data) =>
    await this.apiPut({
      url: `/${group}/org-levels/` + id,
      data,
    });
}

export default new LevelRecordService();
