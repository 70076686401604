import React from "react";
import Loading from "../Loading";
import PropTypes from "prop-types";
import Icon from "../Icon";
import clsx from "clsx";
// import Icon from "../Icon";

const Button = ({
  color,
  size = "md",
  className = "",
  loading,
  icon,
  children,
  disabled,
  bordered,
  shadowed,
  filled,
  clear,
  onClick,
  ...props
}) => {
  const classes = clsx(
    "btn",
    size,
    bordered && "bordered",
    filled && "filled",
    shadowed && "shadowed",
    clear && "clear",
    (!children || children === "") && "no-text",
    color,
    className
  );

  const elements = (
    <React.Fragment>
      {icon && (
        <Icon
          className="icon"
          size="xs"
          {...(typeof icon === "object" ? icon : { icon })}
        />
      )}
      {children}
    </React.Fragment>
  );

  return (
    <button
      className={classes}
      disabled={disabled || loading}
      onClick={(e) => !disabled && !loading && onClick && onClick(e)}
      {...props}
    >
      {loading ? <Loading /> : elements}
    </button>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  color: PropTypes.oneOf([
    "primary",
    "red",
    "green",
    "orange",
    "purple",
    "yellow",
    "light",
    "dark",
    "red-stroke",
  ]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  loading: PropTypes.bool,
};

export default Button;
