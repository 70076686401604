import React from "react";
import { connect } from "react-redux";
import { actions } from "../../../store/reducers/schedule";
import Modal from "../../Modal";
import moment from "moment";
import { capitalize } from "lodash";
import Icon from "../../Icon";
import Badge from "../../Badge";
import Button from "../../Button";
import { confirmToast } from "../../../helpers/Toasts";
import { useAccess } from "../../../helpers/General";

const mstp = ({ schedule: { eventDetails } }) => eventDetails;

function EventDetailsModal({
  open,
  uid,
  editable,
  event = {},
  requestEventDetails,
  requestEventPanel,
  requestDeleteEvent,
  requestActionOnEvent,
}) {
  const { id } = event;

  const access = useAccess(["schedule", "actions"]);

  const onClose = () => requestEventDetails({ open: false });

  const {
    startDate = moment(),
    endDate = moment(),
    leaveStartDate = moment(),
    leaveEndDate = moment(),
    typeName,
    typeColor,
    isLeave,
    userName = "Liah Leonie",
    breaks = [],
    job = {},
    location = {},
    status,
  } = event;

  // console.log(event);

  return (
    <Modal
      sticky
      close={onClose}
      open={open}
      elementId={open ? uid : null}
      className="sch-event-details"
    >
      <header>
        <h2>Event details</h2>
      </header>

      <div className="body">
        <div className="badges">
          <Badge color={typeColor}>{capitalize(typeName)}</Badge>
          <Badge className="status">{status}</Badge>
        </div>

        <div className="user">
          <Icon size="sm" icon="user" />
          {userName}
        </div>

        {!isLeave && (
          <div>
            <Icon size="sm" icon="calendar" />
            {startDate.format("MMMM DD, YYYY")}
          </div>
        )}

        <div className="timing">
          <Icon size="sm" icon={isLeave ? "calendar" : "clock"} />
          {isLeave
            ? leaveStartDate.format("MMMM DD, YYYY")
            : startDate.format("HH:mm a")}
          &ensp;
          <Icon size="xs" icon="arrow-right" className="no-margin" />
          &ensp;
          {isLeave
            ? leaveEndDate.format("MMMM DD, YYYY")
            : endDate.format("HH:mm a")}
        </div>

        {!isLeave && (
          <React.Fragment>
            <div>
              <Icon size="sm" icon="suitcase" />
              {job?.description}
            </div>

            <div>
              <Icon size="sm" icon="map-marker-alt" />
              {location?.description}
            </div>
          </React.Fragment>
        )}
      </div>

      {editable && access && (
        <div className="actions">
          {access.edit && (
            <Button
              icon="pen"
              color="primary"
              onClick={() => {
                requestEventPanel({ open: true, event });
                onClose();
              }}
            >
              Edit
            </Button>
          )}

          {access.delete && (
            <Button
              onClick={() =>
                confirmToast("delete this event", () => {
                  requestDeleteEvent(event);
                  onClose();
                })
              }
              clear
              icon="trash"
              color="red"
              title="Delete"
            />
          )}

          {access.copy && (
            <Button
              onClick={() => {
                requestEventPanel({
                  open: true,
                  event: { ...event, id: undefined },
                });
                onClose();
              }}
              clear
              icon="copy"
              color="yellow"
              title="Copy"
            />
          )}

          <Button
            onClick={() =>
              confirmToast("release this event", () => {
                requestActionOnEvent(id, "release");
                onClose();
              })
            }
            clear
            icon="unlock"
            color="green"
            title="Release"
          />
        </div>
      )}
    </Modal>
  );
}

export default connect(mstp, actions)(EventDetailsModal);
