import { takeEvery, takeLatest } from "redux-saga/effects";
import { types } from "../../reducers/schedule";
import {
  requestActionOnEvent,
  requestDeleteEvent,
  requestEventChange,
  requestSubmitEvent,
  requestBatchActionOnEvents,
} from "./actions";
import { buildConfigForEventFetching } from "./fetching";

export default function* () {
  yield takeLatest(
    [
      types.FETCH_EVENTS,
      types.ADD_FILTER,
      types.REMOVE_FILTER,
      types.CLEAR_FILTERS,
      types.CHANGE_DATE,
      types.CHANGE_MODE,
      types.SELECT_DATE,
      types.CHANGE_SORT,
      types.SET_STATE_FROM_QUERY,
    ],
    buildConfigForEventFetching
  );
  yield takeEvery(types.REQUEST_SUBMIT_EVENT, requestSubmitEvent);
  yield takeEvery(types.REQUEST_DELETE_EVENT, requestDeleteEvent);
  yield takeEvery(types.REQUEST_ACTION_ON_EVENT, requestActionOnEvent);
  yield takeEvery(types.REQUEST_EVENT_CHANGE, requestEventChange);
  yield takeEvery(types.REQUEST_BATCH_ACTION_ON_EVENTS, requestBatchActionOnEvents);
}
