import { takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import BreakRuleService from "../../services/BreakRuleService";

export default function* () {
  yield takeEvery(
    Pagination("breakRules").types().request,
    Pagination("breakRules").saga(BreakRuleService.getAll)
  );
  yield takeEvery(
    CRUD("breakRules").types().submit.request,
    CRUD("breakRules").saga(BreakRuleService).submit({
      status: (value) => Boolean(value),
    })
  );
  yield takeEvery(
    CRUD("breakRules").types().delete.request,
    CRUD("breakRules").saga(BreakRuleService).delete()
  );
}
