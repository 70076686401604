import BaseService from "./BaseService";

class ProfileService extends BaseService {
  requestChangeProfilePicture = async (data) => {
    return await this.apiPut({
      url: "/profile",
      data,
    });
  };
}

export default new ProfileService();
