import { takeEvery, put, select, delay, call } from "redux-saga/effects";
import CRUD from "../../helpers/CRUD";
import { types } from "../reducers/conversations";
import { generateMessage, generateUser } from "./messages";
import moment from "moment";
import ConversationsService from "../../services/ConversationsService";
import Pagination from "../../helpers/Pagination";

const getRandomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const generateString = (length) =>
  [...Array(length)].map(() => Math.random().toString(36)[2]).join("");

const generateConversation = (lastMessageDate = moment(), loggedUser) => ({
  id: generateString(5),
  users:
    Math.random() > 0.2
      ? [generateUser(), loggedUser]
      : [...new Array(getRandomNumber(3, 8))]
          .map(generateUser)
          .concat(loggedUser),
  lastMessage: generateMessage(lastMessageDate),
});

const generateConversations = (count = 5, loggedUser) =>
  [...new Array(count)].map((_, i) =>
    generateConversation(moment().subtract(i * 20, "minutes"), loggedUser)
  );

function* temporaryFetchConversations({ filters: newFilters }) {
  try {
    yield delay(500);

    const oldFilters = yield select(
      ({ conversations }) => conversations.filters
    );

    const loggedUser = yield select(
      ({ authentication }) => authentication.user
    );

    const filters = { ...oldFilters, ...newFilters };

    let conversations = [];

    if (!filters.search) {
      const oldConversations = yield select(
        ({ conversations }) => conversations.conversations
      );

      conversations = oldConversations.concat(
        generateConversations(6, loggedUser)
      );
    }

    yield put({
      type: types.FETCH_CONVERSATIONS_SUCCEEDED,
      conversations,
      total: 12,
      filters,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: types.FETCH_CONVERSATIONS_FAILURE,
      error,
    });
  }
}

function* addUserToConversation({ conversation, users }) {
  try {
    const newParticipants = conversation.participants
      .concat(users)
      .map((user) => user.id);

    const { data } = yield call(
      ConversationsService.updateOne,
      conversation.id,
      {
        ...conversation,
        participants: newParticipants,
      }
    );

    yield put({
      type: types.ADD_USER_TO_CONVERSATION_SUCCEEDED,
      conversation: data,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: types.ADD_USER_TO_CONVERSATION_FAILURE,
      error,
    });
  }
}

function* addConversation({ value: { data, onDone = () => {} } }) {
  const actionTypes = CRUD("conversations").types().submit;

  try {
    const res = yield call(ConversationsService.create, {
      participants: data.participants.map((user) => user.id),
    });

    yield put({
      type: actionTypes.success,
      newEntity: res.data,
    });

    onDone(true);
  } catch (error) {
    console.log(error);
    yield put({
      type: actionTypes.fail,
      error,
    });
    onDone(false);
  }
}

export default function* () {
  yield takeEvery(
    Pagination("conversations").types().request,
    Pagination("conversations").saga(
      ConversationsService.getAll,
      undefined,
      undefined,
      true
    )
  );
  yield takeEvery(
    types.REQUEST_ADD_USER_TO_CONVERSATION,
    addUserToConversation
  );
  yield takeEvery(
    CRUD("conversations").types().submit.request,
    addConversation
  );
}
