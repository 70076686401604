import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";

const initialState = {
  ...Pagination("posts").model(),
  ...CRUD("posts").model(),
};

export default {
  ...initialState,
  filters: {
    ...initialState.filters,
    sortBy: {
      column: "createdAt",
      desc: true,
    },
  },
};
