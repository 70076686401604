import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";

const PaginationDefault = Pagination("messages").model();
const CrudDefault = CRUD("messages").model();

export default {
  ...PaginationDefault,
  ...CrudDefault,
  filters: {
    ...PaginationDefault.filters,
    perPage: 20,
  },
};
