import { takeEvery, put, call } from "redux-saga/effects";
import Axios from "axios";
import { types } from "../reducers/paginated";

export default function* () {
  yield takeEvery("REQUEST_FETCH_PAGINATED", fetchEntities);
}

function* fetchEntities({ value }) {
  const { entity, page, route } = value;

  try {
    const res = yield call(
      Axios.get,
      route || `https://reqres.in/api/users?page=${page}`
    );

    const paginated = res.data.data;

    yield put({
      type: types.FETCH_PAGINATED_SUCCEEDED,
      value: {
        paginated,
        entity,
        done: res.data.total_pages === res.data.page,
      },
    });
  } catch (e) {
    yield put({ type: types.FETCH_PAGINATED_FAILED, value: { entity } });
  }
}
