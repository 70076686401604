import CRUDService from "./CRUDService";

class LevelService extends CRUDService {
  entity = "org-level-groups";
  withFilters = true;

  getGMDashboard(filters) {
    this.apiGetWithFilters({ url: "/dummy" }, filters);
  }
}

export default new LevelService();
