import React, { lazy, useEffect } from "react";
import { connect } from "react-redux";
import { actions } from "../../store/reducers/authentication";
import Loading from "../../components/Loading";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Lazy from "../../components/Lazy";
import { ContextMenuPortal } from "../../components/ContextMenu";
import { ModalPortal } from "../../components/Modal";
import { ToastContainer } from "react-toastify";
import EventDetailsModal from "../../components/Scheduler/EventDetailsModal";
import { AccessTreeContext } from "../..";
const Router = lazy(() => import("../../routes"));

export default connect(
  ({ authentication: { checking, accessTree } }) => ({ checking, accessTree }),
  actions
)(Root);

function Root({ checking, check, accessTree }) {
  useEffect(() => {
    check();
  }, [check]);

  return (
    <AccessTreeContext.Provider value={accessTree}>
      {checking ? (
        <Loading />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <Lazy>
            <Router />
          </Lazy>

          <ToastContainer closeButton={false} />
          <ModalPortal />
          <ContextMenuPortal />
          <EventDetailsModal />
        </DndProvider>
      )}
    </AccessTreeContext.Provider>
  );
}
