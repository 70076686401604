import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";

const PaginationDefault = Pagination("conversations").model();
const CrudDefault = CRUD("conversations").model();

export default {
  ...PaginationDefault,
  ...CrudDefault,
};
