import { takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import UserGroupsService from "../../services/UserGroupsService";

export default function* () {
  yield takeEvery(
    Pagination("userGroups").types().request,
    Pagination("userGroups").saga(UserGroupsService.getAll, ["name"])
  );
  yield takeEvery(
    CRUD("userGroups").types().submit.request,
    CRUD("userGroups")
      .saga(UserGroupsService)
      .submit({
        users: (users) => users ? users.map((user) => user.id) : [],
        status: (value) => Boolean(value),
      })
  );
  yield takeEvery(
    CRUD("userGroups").types().delete.request,
    CRUD("userGroups").saga(UserGroupsService).delete()
  );
}
