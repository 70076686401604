import { takeEvery, put, call, select } from "redux-saga/effects";
import { types } from "../reducers/authentication";
import StorageManager from "../../helpers/StorageManager";
import AuthService from "../../services/AuthService";
import accessTree from "../mockModulesConfig.json";
import config from "../mockConfig.json";
import i18n from "../../i18n";
import LocalizationService from "../../services/LocalizationService";
import { hardAuth } from "../../config";
import { mapDataBySchema } from "../../helpers/General";
import { mapReceivedJobsAndLocations } from "../../helpers/Users";

export default function* () {
  yield takeEvery(types.REQUEST_FETCH_ME, fetchMe);
  yield takeEvery(types.REQUEST_LOGIN, login);
  yield takeEvery(types.REQUEST_LOGOUT, logout);
  yield takeEvery(types.CHECK, (action) => login(action, true));
  yield takeEvery(types.REQUEST_FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(
    types.REQUEST_FORGOT_PASSWORD_CONFIRMATION,
    forgotPasswordConfirmation
  );
  yield takeEvery(types.REQUEST_CHANGE_PASSWORD, changePassword);
}

function* fetchMe() {
  try {
    const res = yield call(AuthService.requestMe);

    if (res && res.data)
      yield put({
        type: types.FETCH_ME_SUCCEEDED,
        user: res.data,
      });
  } catch (e) {
    yield put({ type: types.FETCH_ME_FAILED });
  }
}

function* getUserLocale(lng) {
  try {
    let locale = yield call(LocalizationService.getOne, lng);

    console.log(locale);

    if (locale && locale.data) {
      locale = locale.data;

      i18n.changeLanguage(lng);

      Object.keys(locale).forEach((namespace) => {
        i18n.addResourceBundle(lng, namespace, locale[namespace]);
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getUserAccessTree() {
  try {
    let accessTree = yield call(AuthService.getAccessTree);

    if (accessTree && accessTree.data) {
      accessTree = accessTree.data;
      return accessTree;
    } else return {};
  } catch (error) {
    console.log(error);
    return {};
  }
}

function* login({ data = {} }, isCheck) {
  if (hardAuth) {
    StorageManager.setToSession("token", "weteryetw435ewte");

    yield put({
      type: types.LOGIN_SUCCEEDED,
      user: { company: {} },
      accessTree,
      config,
    });
  } else {
    const { username, password } = data;
    try {
      let res;

      if (isCheck) {
        if (StorageManager.get("token")) {
          res = yield call(AuthService.checkToken);
        } else return yield put({ type: types.LOGIN_FAILED });
      } else res = yield call(AuthService.requestLogin, username, password);

      if (res && res.data.token) {
        const user = mapDataBySchema(res.data.user, {
          jobs: mapReceivedJobsAndLocations,
          locations: mapReceivedJobsAndLocations,
        });

        const token = res.data.token;
        StorageManager.setToSession("token", token);

        const accessTree = yield call(getUserAccessTree, user?.userRole);

        yield put({
          type: types.LOGIN_SUCCEEDED,
          user,
          accessTree,
          config,
        });

        const lng = user?.language;

        if (lng) yield call(getUserLocale, lng);
      }
    } catch (e) {
      console.log(e);
      StorageManager.clearOne("token");

      yield put({ type: types.LOGIN_FAILED, error: e.message });
    }
  }
}

function* logout() {
  try {
    yield call(AuthService.requestLogout);
    StorageManager.clearOne("token");
    yield put({ type: types.LOGOUT_SUCCEEDED });
    yield put({ type: "CLEAR" });
  } catch (e) {
    yield put({ type: types.LOGOUT_FAILED, error: e.message });
  }
}

function* forgotPassword({ username }) {
  try {
    const res = yield call(AuthService.requestForgotPassword, username);

    process.env.NODE_ENV === "development" &&
      console.log(res.data.confirmationCode);

    if (res) {
      yield put({
        type: types.FORGOT_PASSWORD_SUCCEEDED,
        confirmationToken: res.data.confirmationToken,
      });
    }
  } catch (e) {
    console.dir(e);
    yield put({
      type: types.FORGOT_PASSWORD_FAILED,
      error: e.message,
    });
  }
}

function* forgotPasswordConfirmation({
  confirmationCode,
  confirmationToken,
  codeRetries,
}) {
  try {
    const res = yield call(AuthService.requestForgotPasswordConfirmation, {
      confirmationCode,
      confirmationToken,
      codeRetries,
    });

    if (res) {
      if (
        res.data.parentError &&
        res.data.parentError === "Max retries reached, this code is invalid"
      )
        yield put({
          type: types.FORGOT_PASSWORD_FAILED,
          isConfirm: true,
          failed: true,
          error: "Too many retries, please request for a new verification code",
        });
      else if (res.data.codeRetries)
        yield put({
          type: types.FORGOT_PASSWORD_FAILED,
          confirmationToken,
          isConfirm: true,
          error:
            "Wrong code, please check that you have input the 6-digit verification code correctly",
        });
      else
        yield put({
          type: types.FORGOT_PASSWORD_SUCCEEDED,
          isConfirm: true,
          confirmationToken,
        });
    }
  } catch (e) {
    console.log(e.response);
    yield put({
      type: types.FORGOT_PASSWORD_FAILED,
      isConfirm: true,
      error:
        "Something went wrong, please check that you have input the 6-digit verification code correctly",
    });
  }
}

function* changePassword({
  data: {
    password,
    plainPassword,
    oldPassword,
    confirmationToken,
    id,
    sendEmail,
    isReset = false,
  },
}) {
  const requiredToChangePassword = yield select(
    ({ authentication: { requiredToChangePassword } }) =>
      requiredToChangePassword
  );
  const authenticatedUser = yield select(
    ({ authentication: { user } }) => user
  );

  try {
    const res = yield call(
      isReset
        ? AuthService.requestResetPassword
        : AuthService.requestChangePassword,
      isReset
        ? {
            password,
            confirmationToken,
          }
        : {
            id,
            sendEmail,
            oldPassword,
            plainPassword,
            confirmationToken,
          }
    );

    if (res.status === 200) {
      if (Object.keys(res.data).length === 0) {
        yield put({
          type: types.CHANGE_PASSWORD_SUCCEEDED,
          user: authenticatedUser,
          shouldAuthenticated: authenticatedUser ? true : false,
        });
      } else {
        yield put({
          type: types.CHANGE_PASSWORD_SUCCEEDED,
          user: requiredToChangePassword ? res.data.user : res.data,
        });
      }
    }
  } catch (e) {
    console.dir(e);
    yield put({
      type: types.CHANGE_PASSWORD_FAILED,
      error: e.message,
    });
  }
}
