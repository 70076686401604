import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import StorageManager from "../helpers/StorageManager";

import reducers from "./allReducers";
import sagas from "./allSagas";
import model from "./models";

const appReducer = combineReducers(reducers);

const rootReducer = (state = model, action) => {
  if (action.type === "CLEAR") {
    console.info("Cleared all store");

    StorageManager.clearOne("token");

    return appReducer(
      { authentication: { ...model.authentication, checking: false } },
      action
    );
  }

  return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  // applyMiddleware(sagaMiddleware)
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(sagas);

export default store;
