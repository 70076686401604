import { createReducer, createActions } from "reduxsauce";
import initialState from "../models/conversations";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";

const { Types: types, Creators: actions } = createActions({
  ...Pagination("conversations").actions(),
  ...CRUD("conversations").actions(),
  // add user to convo
  requestAddUserToConversation: ["conversation", "users"],
  addUserToConversationSucceeded: null,
  addUserToConversationFailure: null,
});

export default createReducer(initialState, {
  ...Pagination("conversations").reducer(),
  ...CRUD("conversations").reducer(),
  [types.REQUEST_ADD_USER_TO_CONVERSATION]: (state) => ({
    ...state,
    loading: true,
  }),
  [types.ADD_USER_TO_CONVERSATION_SUCCEEDED]: (
    { conversations, ...state },
    { conversation }
  ) => ({
    ...state,
    loading: false,
    conversations: conversations.map((mappedConvo) => 
      mappedConvo.id === conversation.id ? conversation : mappedConvo
    ),
  }),
  [types.ADD_USER_TO_CONVERSATION_FAILURE]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
  SUBMIT_MESSAGES_SUCCEEDED: (
    { id, conversations, ...state }, 
    { newEntity }
  ) => ({
    ...state,
    id,
    conversations: conversations.map(
      (conversation) => conversation.id === id
        ? ({ ...conversation, lastMessage: newEntity })
        : conversation
    ),
  }),
  // overides 
  [types.SUBMIT_CONVERSATIONS_SUCCEEDED]: (state, { newEntity }) => ({
    ...state,
    submitting: false,
    error: null,
    total: state.total + 1,
    conversations: state.conversations.concat(newEntity),
    id: newEntity.id,
  }),
  [types.REQUEST_FETCH_CONVERSATIONS]: (state) => ({
    ...state,
    loading: true,
  }),
});

export { types, actions };
