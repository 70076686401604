import initialState from "../models/authentication";
import { createActions, createReducer } from "reduxsauce";

const { Types: types, Creators: actions } = createActions({
  check: null,
  resetAuth: null,
  requestLogin: ["data"],
  loginFailed: null,
  loginSucceeded: null,
  requestLogout: null,
  logoutFailed: null,
  logoutSucceeded: null,
  requestUpdateCompany: ["company"],
  requestForgotPassword: ["username"],
  requestForgotPasswordConfirmation: [
    "confirmationCode",
    "confirmationToken",
    "codeRetries",
  ],
  forgotPasswordFailed: ["error"],
  forgotPasswordSucceeded: ["confirmationToken"],
  requestChangePassword: ["data"],
  changePasswordFailed: ["error"],
  changePasswordSucceeded: null,
  requestFetchMe: null,
  fetchMeSucceeded: null,
  fetchMeFailed: null,
  editMeSucceeded: ["user"],
});

export default createReducer(initialState, {
  [types.RESET_AUTH]: (state) => ({
    ...state,
    forgotPassword: { ...initialState.forgotPassword },
    changePassword: { ...initialState.changePassword },
  }),
  [types.REQUEST_LOGIN]: (state) => ({
    ...state,
    error: false,
    checking: false,
    loggingIn: true,
  }),
  [types.LOGIN_SUCCEEDED]: (state, { user, accessTree, config }) => ({
    ...state,
    requiredToChangePassword: user.requirePasswordChange,
    authenticated: !user.requirePasswordChange,
    user,
    accessTree,
    config,
    error: false,
    checking: false,
  }),
  [types.LOGIN_FAILED]: (state, { error }) => ({
    ...state,
    error,
    loggingIn: false,
    checking: false,
  }),
  [types.REQUEST_LOGOUT]: (state) => ({
    ...state,
    loggingOut: true,
  }),
  [types.LOGOUT_FAILED]: (state) => ({
    ...state,
    loggingOut: false,
    error: true,
  }),
  [types.REQUEST_UPDATE_COMPANY]: ({ user, ...state }, { company }) => ({
    ...state,
    user: {
      ...user,
      company,
    },
  }),
  [types.REQUEST_FORGOT_PASSWORD]: ({ forgotPassword, ...state }) => ({
    ...state,
    forgotPassword: {
      ...forgotPassword,
      loading: true,
      error: false,
      success: false,
    },
  }),
  [types.REQUEST_FORGOT_PASSWORD_CONFIRMATION]: (state) => ({
    ...state,
    forgotPassword: {
      ...state.forgotPassword,
      loading: true,
      error: false,
      success: false,
    },
  }),
  [types.FORGOT_PASSWORD_FAILED]: (state, { error, failed }) => ({
    ...state,
    forgotPassword: {
      ...state.forgotPassword,
      confirmationToken: failed ? null : state.forgotPassword.confirmationToken,
      loading: false,
      error,
      success: false,
    },
  }),
  [types.FORGOT_PASSWORD_SUCCEEDED]: (
    state,
    { isConfirm, confirmationToken }
  ) => ({
    ...state,
    forgotPassword: {
      confirmationToken,
      loading: false,
      success: !isConfirm,
      successConfirm: isConfirm,
      error: false,
    },
    changePassword: { ...state.changePassword, confirmationToken },
  }),
  [types.REQUEST_CHANGE_PASSWORD]: (state) => ({
    ...state,
    changePassword: {
      loading: true,
      error: false,
      success: false,
    },
  }),
  [types.CHANGE_PASSWORD_FAILED]: (state, { error }) => ({
    ...state,
    changePassword: {
      loading: false,
      error,
      success: false,
    },
  }),
  [types.CHANGE_PASSWORD_SUCCEEDED]: (
    { authenticated, ...state },
    { user, shouldAuthenticated }
  ) => ({
    ...state,
    user,
    authenticated: authenticated || shouldAuthenticated ? true : false,
    requiredToChangePassword: false,
    changePassword: {
      loading: false,
      success: true,
      error: false,
    },
  }),
  [types.REQUEST_FETCH_ME]: (state = initialState) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [types.FETCH_ME_SUCCEEDED]: (state = initialState, { user }) => ({
    ...state,
    loading: false,
    error: false,
    user,
  }),
  [types.FETCH_ME_FAILED]: (state = initialState, { error = "" }) => ({
    ...state,
    loading: false,
    error,
  }),
  [types.EDIT_ME_SUCCEEDED]: (state, { user }) => ({
    ...state,
    user,
  }),
});

export { types, actions };
