export const ROW_HEIGHT = 70;

export const EVENT_HEIGHT = 60;

export const ROW_PADDING = 5;

export const EVENT_STATUSES = {
  APPROVED: "approved",
  SUBMITTED: "submitted",
  RELEASED: "released",
  PENDING: "pending",
  REJECTED: "rejected",
};

export const FILTERS = {
  SORT_BY: {
    NAME: "name",
    EVENT_START: "eventStart",
    LOCATION: "location",
    JOB: "job",
  },
  LOCATION: "location",
  EVENT_TYPE: "eventType",
  EVENT_STATUS: "eventStatus",
  USER: "user",
  IS_SELF: "isSelf",
};
