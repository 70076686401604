import CRUDService from "./CRUDService";

class NotificationService extends CRUDService {
  entity = "notifications";
  withFilters = true;

  markAsRead = (id) => 
    this.apiPut({
      url: "notifications/mark-read/" + id,
    });

  markAsUnread = (id) => 
    this.apiPut({
      url: "notifications/mark-unread/" + id,
    });
}

export default new NotificationService();
