import { call, put, takeEvery, select } from "redux-saga/effects";
import PostService from "../../services/PostService";
import { types } from "../reducers/comments";
import { toast } from "react-toastify";

export default function* () {
  yield takeEvery(types.REQUEST_FETCH_POST_COMMENTS, fetchPostComments);
  yield takeEvery(types.REQUEST_COMMENT_ON_POST, commentOnPost);
  yield takeEvery(types.REQUEST_DELETE_COMMENT, deleteComment);
  yield takeEvery(types.REQUEST_LIKE_COMMENT, likeComment);
}

function* fetchPostComments({ postId, page }) {
  try {
    const { data } = yield call(
      PostService.getPostComments, 
      postId, 
      { 
        page, 
        perPage: 10, 
        sortBy: { 
          value: "desc",
          name: "orderBy[createdAt]",
        }, 
      },
    );

    const { result, totalItems } = data;

    yield put({ 
      type: types.FETCH_POST_COMMENTS_SUCCEEDED, 
      postId, 
      page, 
      comments: result,
      total: totalItems,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: types.FETCH_POST_COMMENTS_FAILURE });
  }
}

function* commentOnPost({ postId, comment }) {
  try {
    const currentUser = yield select(
      ({ authentication }) => authentication.user
    );

    const { data } = yield call(
      PostService.requestCommentOnPost,
      postId,
      comment,
    );

    yield put({
      type: types.COMMENT_ON_POST_SUCCEEDED,
      postId,
      comment: data,
      user: currentUser,
    });
    toast.success("Post commented succesfully");
  } catch (error) {
    yield put({
      type: types.COMMENT_ON_POST_FAILURE,
      postId,
      error,
    });
  }
}

function* deleteComment({ postId, commentId }) {
  try {
    const res = yield call(
      PostService.requestDeleteComment, 
      postId,
      commentId,
    );

    if (res) {
      yield put({
        type: types.DELETE_COMMENT_SUCCEEDED,
        postId, 
        commentId,
      });

      toast.success("Comment deleted succesfully");
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: types.DELETE_COMMENT_FAILURE,
      postId, 
      error: e.message,
    });
  }
}

function* likeComment({ postId, commentId, liked }) {
  try {
    yield call(
      liked 
        ? PostService.requestUnlikePost
        : PostService.requestLikePost, 
      commentId,
    );

    yield put({
      type: types.LIKE_COMMENT_SUCCEEDED,
      postId,
      commentId,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: types.LIKE_COMMENT_FAILURE,
      postId,
      error: e.message,
    });
  }
}

