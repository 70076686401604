import initialState from "../../models/schedule";
import { createReducer, createActions } from "reduxsauce";
import { cloneDeep, lowerCase } from "lodash";
import fetching, { actions as fetchingActions } from "./fetching";
import filters, { actions as filtersActions } from "./filters";
import events, { actions as eventsActions } from "./events";
import crud, { actions as crudActions } from "./crud";

const { Types: types, Creators: actions } = createActions({
  requestResetScheduler: null,
  requestNewDimensions: ["minuteWidth"],
  setTooltip: ["value"],
  setStateFromQuery: ["state"],
  ...fetchingActions,
  ...eventsActions,
  ...crudActions,
  ...filtersActions,
});

export default createReducer(initialState, {
  [types.REQUEST_RESET_SCHEDULER]: () => cloneDeep(initialState),
  [types.REQUEST_NEW_DIMENSIONS]: (state, { minuteWidth: MINUTE_WIDTH }) => ({
    ...state,
    dimensions: {
      ...state.dimensions,
      MINUTE_WIDTH,
    },
  }),
  [types.SET_TOOLTIP]: (state, { value: tooltip }) => ({
    ...state,
    tooltip,
  }),
  [types.SET_STATE_FROM_QUERY]: (state, { state: { filters, ...rest } }) => {
    const newState = {
      ...state,
      ...rest,
    };

    if (rest.date) newState.date.startOf(lowerCase(newState.mode));

    return newState;
  },

  ...filters(types),
  ...fetching(types),
  ...events(types),
  ...crud(types),
});

export { types, actions };
