import BaseService from "./BaseService";
import moment from "moment";

class AuthService extends BaseService {
  checkToken = async () => {
    return await this.apiGet({
      url: "/check-token",
    });
  };

  requestLogin = async (username, password) => {
    return await this.apiPost({
      url: "/login",
      data: { username, password },
    });
  };

  requestLogout = async () => this.apiGet({ url: "/logout" });

  requestForgotPassword = async (username) => {
    return await this.apiPost({
      url: "/profile/forgot-password",
      data: { username },
    });
  };

  requestForgotPasswordConfirmation = async ({
    confirmationCode,
    confirmationToken,
    codeRetries,
  }) => {
    return await this.apiPost({
      url: "/profile/forgot-password/verify",
      data: {
        confirmationCode,
        confirmationToken,
        codeRetries,
        passwordRequestedAt: moment().toISOString(),
      },
    });
  };

  requestResetPassword = async ({ password, confirmationToken }) => {
    return await this.apiPost({
      url: "/profile/forgot-password/reset",
      data: { password, confirmationToken },
    });
  };

  requestChangePassword = async ({
    plainPassword,
    oldPassword,
    id,
    sendEmail,
  }) => {
    const isEdit = !!id;

    return await this.apiPut({
      url: isEdit ? `/users/change-password/${id}` : "/profile/change-password",
      data: isEdit
        ? { plainPassword, sendEmail }
        : { plainPassword, oldPassword },
    });
  };

  requestMe = async () =>
    this.apiGet({
      url: "/profile",
    });

  editProfile = async (data) =>
    this.apiPut({
      url: "/profile",
      data,
    });

  getAccessTree = async () =>
    this.apiGet({
      url: "/profile/role",
    });
}

export default new AuthService();
