import initialState from "../models/comments";
import { createReducer, createActions } from "reduxsauce";
import { getUpdatedLikedPost } from "./posts";

const { Types: types, Creators: actions } = createActions({
  requestFetchPostComments: ["postId", "page"],
  fetchPostCommentsSucceeded: null,
  fetchPostCommentsFailure: null,
  requestCommentOnPost: ["postId", "comment"],
  commentOnPostSucceeded: null,
  commentOnPostFailure: null,
  requestDeleteComment: ["postId", "commentId"],
  deleteCommentSucceeded: null,
  deleteCommentFailure: null,
  requestLikeComment: ["postId", "commentId", "liked"],
  likeCommentSucceeded: null,
  likeCommentFailure: null,
});

export default createReducer(initialState, {
  // manage state on post changes
  FETCH_POSTS_SUCCEEDED: (state, { posts }) => {
    const newPosts = { ...state.posts };

    posts.forEach(({ id, commentsCount = 0 }) => 
      newPosts[id] = ({
        comments: [],
        total: commentsCount,
        page: 1,
        loading: false,
        submitting: false,
      })
    );

    return {
      ...state.posts,
      posts: newPosts,
    };
  },
  SUBMIT_POSTS_SUCCEEDED: (state, { newEntity }) => {
    const newPosts = { ...state.posts };

    newPosts[newEntity.id] = {
      comments: [],
      total: 0,
      page: 1,
      loading: false,
      submitting: false,
    };

    return {
      ...state,
      posts: newPosts,
    };
  },

  // manage state on comments changes
  [types.REQUEST_FETCH_POST_COMMENTS]: ({ posts }, { postId }) => ({
    posts: {
      ...posts,
      [postId]: { 
        ...posts[postId],
        loading: true, 
      },
    }
  }),
  [types.FETCH_POST_COMMENTS_SUCCEEDED]: (
    { posts }, 
    { postId, page, comments, total },
  ) => ({
    posts: {
      ...posts,
      [postId]: { 
        ...posts[postId],
        comments: posts[postId].comments.concat(comments),
        loading: false,
        page,
        total,
      },
    }
  }),
  [types.FETCH_POST_COMMENTS_FAILURE]: (
    { posts }, 
    { postId, error },
  ) => ({
    posts: {
      ...posts,
      [postId]: { 
        ...posts[postId],
        error,
      },
    }
  }),
  [types.REQUEST_COMMENT_ON_POST]: (
    { posts }, 
    { postId },
  ) => ({
    posts: {
      ...posts,
      [postId]: { 
        ...posts[postId],
      submitting: true,
      },
    }
  }),
  [types.COMMENT_ON_POST_SUCCEEDED]: (
    { posts }, 
    { postId, comment, user },
  ) => ({
    posts: {
      ...posts,
      [postId]: { 
        ...posts[postId],
        submitting: false,
        comments: posts[postId].comments.concat({ 
          ...comment, 
          createdBy: user,
        }),
        total: posts[postId].total + 1,
      },
    }
  }),
  [types.COMMENT_ON_POST_FAILURE]: (
    { posts }, 
    { postId, error },
  ) => ({
    posts: {
      ...posts,
      [postId]: { 
        ...posts[postId],
        submitting: false,
        error,
      },
    }
  }),
  [types.DELETE_COMMENT_SUCCEEDED]: ({ posts }, { postId, commentId }) => ({
    posts: {
      ...posts,
      [postId]: { 
        ...posts[postId],
        submitting: false,
        total: posts[postId].total - 1,
        comments: posts[postId].comments.filter(
          (comment) => comment.id !== commentId
        ),
      },
    },
  }),
  [types.DELETE_COMMENT_FAILURE]: ({ posts }, { postId, error }) => ({
    posts: {
      ...posts,
      [postId]: { 
        ...posts[postId],
        error,
      },
    },
  }),
  [types.LIKE_COMMENT_SUCCEEDED]: (
    { posts },
    { postId, commentId }
  ) => ({
    posts: {
      ...posts,
      [postId]: { 
        ...posts[postId],
        comments: posts[postId].comments.map(
          (comment) => comment.id === commentId
            ? getUpdatedLikedPost(comment)
            : comment
        ),
      },
    },
  }),
  [types.LIKE_COMMENT_FAILURE]: ({ posts }, { postId, error }) => ({
    posts: {
      ...posts,
      [postId]: { 
        ...posts[postId],
        error,
      },
    },
  }),
});

export { types, actions };
