import CRUDService from "./CRUDService";

class UserRolesService extends CRUDService {
  withFilters = true;
  entity = "user-roles";

  getDefaultConfig = () =>
    this.apiGet({
      url: "/default-modules-access",
    });
}

export default new UserRolesService();
