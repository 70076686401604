import { takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import LocationGroupsService from "../../services/LocationGroupsService";

export default function* () {
  yield takeEvery(
    Pagination("locationGroups").types().request,
    Pagination("locationGroups").saga(LocationGroupsService.getAll, [
      "code",
      "description",
    ])
  );
  yield takeEvery(
    CRUD("locationGroups").types().submit.request,
    CRUD("locationGroups").saga(LocationGroupsService).submit({
      status: (value) => Boolean(value),
    })
  );
  yield takeEvery(
    CRUD("locationGroups").types().delete.request,
    CRUD("locationGroups").saga(LocationGroupsService).delete()
  );
}
