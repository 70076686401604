import { toast } from "react-toastify";
import { select, call, put, all } from "redux-saga/effects";
import { mapSubmittedEventData, getNewStatus } from "../../../helpers/Events";
import EventService from "../../../services/EventService";
import { types } from "../../reducers/schedule";
import { types as leaveTypes } from "../../reducers/leaves";
import moment from "moment";
import LeaveService from "../../../services/LeaveService";

export function* requestSubmitEvent({ data: submittedData }) {
  const eventId = yield select(
    ({
      schedule: {
        eventPanel: { event },
      },
    }) => event && event.id
  );

  const data = mapSubmittedEventData(submittedData);
  const service = data.isLeave ? LeaveService : EventService;

  console.log(data);
  try {
    const res = yield eventId
      ? call(service.updateOne, eventId, data)
      : call(service.createOne, data);

    const newEvent = res.data;

    yield put({
      type: types.SUBMIT_EVENT_SUCCEEDED,
      newEvent:
        data.isLeave && moment(data.endDate).isAfter(moment(), "date")
          ? []
          : { ...newEvent, isLeave: data.isLeave },
    });

    toast.success(`${data.isLeave ? "Leave" : "Event"} saved successfully`);
  } catch (error) {
    console.log(error);
    yield put({
      type: types.SUBMIT_EVENT_FAILED,
      error,
    });
  }
}

export function* requestDeleteEvent({ event }) {
  try {
    const service = event.isLeave ? LeaveService : EventService;
    const res = yield call(service.deleteOne, event.id);

    yield put({
      type: types.DELETE_EVENT_SUCCEEDED,
      event,
    });

    toast.success(`${event.isLeave ? "Leave" : "Event"} deleted successfully`);
  } catch (error) {
    console.log(error);

    yield put({
      type: types.DELETE_EVENT_FAILED,
      error,
    });
  }
}

export function* requestActionOnEvent({ action, event }) {
  try {
    const service = event.isLeave ? LeaveService : EventService;

    const res = yield call(service.actionOnOne, action, event.id);

    yield put({
      type: types.ACTION_ON_EVENT_SUCCEEDED,
      newEvent: {
        ...(event.isLeave ? res.data : event),
        status: getNewStatus(action),
        isLeave: event.isLeave,
      },
    });

    if (event.isLeave)
      yield put({
        type: leaveTypes.ACTION_ON_LEAVE_SUCCEEDED,
        event: { ...event, status: getNewStatus(action) },
      });

    toast.success(`${event.isLeave ? "Leave" : "Event"} updated successfully`);
  } catch (error) {
    yield put({
      type: types.ACTION_ON_EVENT_FAILED,
      error,
    });
  }
}

export function* requestEventChange({ value }) {
  try {
    const { newEvent: data } = value;

    yield call(EventService.updateOne, data.id, mapSubmittedEventData(data));

    yield put({
      type: types.EVENT_CHANGE_SUCCEEDED,
      value,
    });
  } catch (error) {
    console.log(error);

    yield put({
      type: types.EVENT_CHANGE_FAILED,
      error,
      oldEvent: value.oldEvent,
    });
  }
}

function* callActionOnEvent(event, action) {
  try {
    return yield call(EventService.actionOnOne, action, event.id);
  } catch (error) {
    console.log(error);
  }
}

function copyEvent({ event, dayDifference, options }) {
  try {
    const {
      startDate,
      endDate,
      eventType,
      location,
      job,
      user: eventUser,
      ...rest
    } = event;

    const { users } = options;

    const newEvent = {
      ...rest,
      startDate: moment(startDate).add(dayDifference, "days").toISOString(),
      endDate: moment(endDate).add(dayDifference, "days").toISOString(),
      user: eventUser.id,
      eventType: eventType.id,
      location: location.id,
      job: job.id,
    };

    if (users)
      return users.map((user) =>
        call(EventService.createOne, { ...newEvent, user: user.id })
      );
    else return call(EventService.createOne, newEvent);
  } catch (error) {
    console.log(error);
  }
}

const getEarliestEvent = (events) =>
  events.sort((e1, e2) => (e1.startDate > e2.startDate ? 1 : -1))[0];

const getDayDifference = (events, date) =>
  moment(date)
    .startOf("day")
    .diff(moment(getEarliestEvent(events).startDate).startOf("day"), "days");

function* tasksMapper({ events, action, options }) {
  switch (action) {
    case "delete":
      return events.map((event) => call(EventService.deleteOne, event.id));
    case "publish":
      const currentUserId = yield select(
        ({ authentication }) => authentication.user.id
      );

      return events.map((event) =>
        call(
          callActionOnEvent,
          event,
          currentUserId === event.user.id ? "publish" : "submit"
        )
      );
    case "copy":
      const { date } = options;

      const dayDifference = getDayDifference(events, date);

      return events.flatMap((event) =>
        copyEvent({ event, dayDifference, options })
      );
    default:
      break;
  }
}

function* updatedEventsMapper({ responses, events, action }) {
  switch (action) {
    case "delete":
      return [];
    case "publish":
      const currentUserId = yield select(
        ({ authentication }) => authentication.user.id
      );

      return responses.map((_, i) => ({
        ...events[i],
        status: currentUserId === events[i].user.id ? "approved" : "submitted",
      }));
    case "copy":
      return responses.filter((r) => r).map((response) => response.data);
    default:
      break;
  }
}

function oldEventsMapper({ responses, events, action }) {
  switch (action) {
    case "copy":
      return [];
    default:
      return responses.reduce(
        (oldEvents, response, i) => response && oldEvents.concat(events[i]),
        []
      );
  }
}

function splitTasksIntoGroups(tasks, perGroup) {
  let taskGroups = [];

  for (let i = 0; i < tasks.length; i += perGroup) {
    taskGroups.push(tasks.slice(i, i + perGroup));
  }

  return taskGroups;
}

function* runTasksInGroups(tasks, perGroup) {
  const taskGroups = splitTasksIntoGroups(tasks, perGroup);
  let responses = [];

  for (const group of taskGroups) {
    const groupResponses = yield all(group);
    responses.push(...groupResponses);
  }

  return responses;
}

export function* requestBatchActionOnEvents({ events, action, options }) {
  try {
    const tasks = yield tasksMapper({ events, action, options });

    const responses = yield runTasksInGroups(tasks, 10);

    const updatedEvents = yield updatedEventsMapper({
      responses,
      events,
      action,
    });

    const oldEvents = yield oldEventsMapper({
      events,
      responses,
      action,
    });

    yield put({
      type: types.BATCH_ACTION_ON_EVENTS_SUCCEEDED,
      events,
      updatedEvents,
      oldEvents,
      action,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: types.BATCH_ACTION_ON_EVENTS_FAILED,
      events,
      action,
      error,
    });
  }
}
