import { put, call, select, all, fork } from "redux-saga/effects";
import { types } from "../../../reducers/schedule";
import EventService from "../../../../services/EventService";
import { schedulerModes } from "../../../../config";
import { FILTERS } from "../../../../components/Scheduler/constants";
import UserService from "../../../../services/UserService";
import LocationService from "../../../../services/LocationService";
import EventTypeService from "../../../../services/EventTypeService";
import { fetchUserEvents } from "./fetchUserEvents";
import { fetchDateEvents } from "./fetchDateEvents";
import { fetchMonthEvents } from "./fetchMonthEvents";
import { keys, startCase } from "lodash";

export function* buildConfigForEventFetching(action = {}) {
  const { type, value } = action;

  let state = yield select(
    ({
      schedule: {
        mode,
        date,
        selectedDate,
        filtersPanel: { filters },
      },
    }) => ({
      mode,
      date,
      selectedDate,
      filters,
    })
  );

  if (type === types.SET_STATE_FROM_QUERY)
    state = yield populateDecodedFilters(state, action.state);

  let tasks = [];

  if (type === types.FETCH_EVENTS && value) {
    const mapping = value.mapping;

    switch (mapping) {
      case "user":
        tasks.push(fork(fetchUserEvents, state, action));
        break;

      case "date":
        tasks.push(fork(fetchDateEvents, state, action));
        break;

      default:
        break;
    }
  } else {
    switch (state.mode) {
      case schedulerModes.DAY:
      case schedulerModes.WEEK:
        tasks.push(fork(fetchUserEvents, state, action));
        break;

      case schedulerModes.MONTH:
        if (type === types.SELECT_DATE) {
          if (state.selectedDate)
            tasks.push(fork(fetchUserEvents, state, action));
        } else {
          if (state.selectedDate)
            tasks.push(fork(fetchUserEvents, state, action));
          tasks.push(fork(fetchMonthEvents, state, action));
        }
        break;

      default:
        break;
    }
  }

  try {
    yield all(tasks);
  } catch (error) {
    console.log(error);
    yield put({ type: types.FETCH_EVENTS_FAILED, error });
  }
}

function* populateDecodedFilters(oldState, { filters, ...rest }) {
  if (
    filters.hasOwnProperty(FILTERS.EVENT_TYPE) ||
    filters.hasOwnProperty(FILTERS.LOCATION) ||
    filters.hasOwnProperty(FILTERS.USER) ||
    filters.hasOwnProperty(FILTERS.EVENT_STATUS) ||
    filters.hasOwnProperty(FILTERS.IS_SELF)
  ) {
    const newState = {
        ...oldState,
        ...rest,
        filters: { ...oldState.filters, ...filters },
      },
      tasks = {};

    Object.keys(filters).forEach((filter) => {
      const value = newState.filters[filter];

      switch (filter) {
        case FILTERS.USER:
          tasks[FILTERS.USER] = call(UserService.getOne, value);
          break;

        case FILTERS.LOCATION:
          tasks[FILTERS.LOCATION] = call(LocationService.getOne, value);
          break;

        case FILTERS.EVENT_TYPE:
          tasks[FILTERS.EVENT_TYPE] = call(EventTypeService.getAll, {
            id: value,
          });
          break;

        case FILTERS.IS_SELF:
          newState.filters[FILTERS.IS_SELF] = value;
          break;

        case FILTERS.EVENT_STATUS:
          newState.filters[FILTERS.EVENT_STATUS] = value.map((value) => ({
            value,
            label: startCase(value),
          }));
          break;

        default:
          break;
      }
    });

    // console.log({ newState, filters });
    try {
      const res = yield all(tasks);

      keys(res).forEach((filter) => {
        const value = res[filter].data?.result || res[filter].data;
        newState.filters[filter] = value;
      });

      yield put({ type: types.UPDATE_FILTERS, filters: newState.filters });

      return newState;
    } catch (error) {
      console.log(error);
    }
  } else return oldState;
}

export const getFilters = (filters, forEntity = "user") =>
  Object.keys(filters).reduce((newFilters, filter) => {
    const value = filters[filter];

    switch (filter) {
      case FILTERS.LOCATION:
        newFilters[FILTERS.LOCATION] = value.id;
        break;

      case "sortBy": {
        if (forEntity === "user")
          switch (value.field) {
            case FILTERS.SORT_BY.EVENT_START:
              if (forEntity === "user")
                newFilters["orderBy[events.startDate]"] = value.order;
              break;

            case FILTERS.SORT_BY.JOB:
              if (forEntity === "user")
                newFilters["orderBy[jobs]"] = value.order;
              break;

            case FILTERS.SORT_BY.LOCATION:
              if (forEntity === "user")
                newFilters["orderBy[locations]"] = value.order;
              break;

            case FILTERS.SORT_BY.NAME:
              if (forEntity === "user") {
                newFilters["orderBy[firstName]"] = value.order;
                newFilters["orderBy[middleName]"] = value.order;
                newFilters["orderBy[lastName]"] = value.order;
              }
              break;
          }

        break;
      }

      case FILTERS.EVENT_TYPE:
        if (value) newFilters[FILTERS.EVENT_TYPE] = value.map(({ id }) => id);
        break;

      case FILTERS.EVENT_STATUS: {
        if (value) {
          const filterName = forEntity === "user" ? "eventStatus" : "status";

          newFilters[filterName] = value.map(({ value }) => value);
        }
        break;
      }

      case FILTERS.USER:
        newFilters[forEntity === "user" ? "id" : "user"] = value?.id;
        break;

      case "to":
      case "from":
        newFilters[filter] = value?.toISOString();
        break;

      default:
        break;
    }

    return newFilters;
  }, {});

export function* fetchEvents(filters) {
  try {
    let res = yield call(EventService.getEvents, filters);

    const { result: events, totalItems: total } = res.data;

    return { events, total, date: filters.from };
  } catch (e) {
    console.log(e);
    return { events: [], total: 0, date: filters.from };
  }
}
