import { takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import LocationService from "../../services/LocationService";

export default function* () {
  yield takeEvery(
    Pagination("locations").types().request,
    Pagination("locations").saga(LocationService.getAll, [
      "code",
      "description",
    ])
  );
  yield takeEvery(
    CRUD("locations").types().submit.request,
    CRUD("locations").saga(LocationService).submit({
      status: (value) => Boolean(value),
      group: (value) => {
        if (!value) return undefined;
        else return value.id;
      }
    })
  );
  yield takeEvery(
    CRUD("locations").types().delete.request,
    CRUD("locations").saga(LocationService).delete()
  );
}
