import moment from "moment";
import { call, put, select } from "redux-saga/effects";
import { FILTERS } from "../../../../components/Scheduler/constants";
import { types } from "../../../reducers/schedule";
import { fetchEvents, getFilters } from ".";

export function* fetchDateEvents(state, action) {
  const { date, authenticatedUser } = yield select(
    ({
      authentication: { user: authenticatedUser },
      schedule: {
        dateEvents: { date },
      },
    }) => ({ date, authenticatedUser })
  );

  const { filters } = state;

  const { page, reset } = action.value;

  try {
    const { events, total } = yield call(fetchEvents, {
      ...getFilters(
        {
          ...filters,
          from: moment(date).startOf("day"),
          to: moment(date).endOf("day"),
        },
        "date"
      ),
      "orderBy[startDate]": "asc",
      perPage: 3,
      page,
      user: filters[FILTERS.IS_SELF] ? authenticatedUser.id : undefined,
    });

    yield put({
      type: types.FETCH_DATE_EVENTS_SUCCEEDED,
      total,
      events,
      page,
      reset,
    });
  } catch (error) {
    console.log(error);

    yield put({ type: types.FETCH_DATE_EVENTS_FAILED, error });
  }
}
