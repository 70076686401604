import { camelCase, upperFirst, snakeCase, lowerCase, trimEnd } from "lodash";
import { call, select, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { mapDataBySchema } from "./General";

const typeCase = (entity) => snakeCase(entity).toUpperCase();
const formatName = (entity) => trimEnd(upperFirst(lowerCase(entity)), "s");

const CRUD = (entity) => {
  const model = () => ({
    error: null,
    [entity]: [],
    id: null,
    submitting: false,
  });

  const types = () => ({
    id: { set: `REQUEST_SET_${typeCase(entity)}_ID` },
    submit: {
      request: `REQUEST_SUBMIT_${typeCase(entity)}`,
      success: `SUBMIT_${typeCase(entity)}_SUCCEEDED`,
      fail: `SUBMIT_${typeCase(entity)}_FAILED`,
    },
    delete: {
      request: `REQUEST_DELETE_${typeCase(entity)}`,
      success: `DELETE_${typeCase(entity)}_SUCCEEDED`,
      fail: `DELETE_${typeCase(entity)}_FAILED`,
    },
  });

  const actions = () => ({
    // submit
    [camelCase(types().submit.request)]: ["value"],
    [camelCase(types().submit.success)]: null,
    [camelCase(types().submit.fail)]: ["error"],
    // delete
    [camelCase(types().delete.request)]: ["index", "data"],
    [camelCase(types().delete.success)]: null,
    [camelCase(types().delete.fail)]: ["error"],
    // id
    [camelCase(types().id.set)]: ["index"],
  });

  const reducer = () => ({
    [types().submit.request]: (state) => ({
      ...state,
      error: null,
      submitting: true,
    }),
    [types().submit.success]: (
      { id: stateId, ...state },
      { newEntity, parentId }
    ) => {
      const id = parentId || stateId;

      return {
        ...state,
        submitting: false,
        error: null,
        total: id ? state.total : state.total + 1,
        [entity]: id
          ? state[entity].map((current) =>
              current.id === id ? newEntity : current
            )
          : [...state[entity], newEntity],
      };
    },
    [types().submit.fail]: (state, { error = "" }) => ({
      ...state,
      submitting: false,
      error,
    }),
    [types().delete.request]: (state) => ({
      ...state,
      error: null,
      loading: true,
    }),
    [types().delete.success]: (state, { index }) => {
      const newEntities = state[entity].map((data, i) =>
        i === index ? { ...data, status: !data.status } : data
      );

      return {
        ...state,
        loading: false,
        error: null,
        [entity]: newEntities,
        total: state.total - 1,
      };
    },
    [types().delete.fail]: (state, { error = "" }) => ({
      ...state,
      loading: false,
      error,
    }),
    [types().id.set]: (state, { index }) => {
      // console.log(state[entity][index].id);
      return {
        ...state,
        error: null,
        id: index === null ? index : state[entity][index].id,
      };
    },
  });

  const saga = (service) => ({
    submit: (mappingSchema) =>
      function* ({
        value: {
          data: unmappedData,
          id: parentId,
          onDone = () => {},
          extraServiceArgs = [],
        },
      }) {
        let id = parentId;

        if (!id) id = yield select((state) => state[entity].id);

        const data = mapDataBySchema(unmappedData, mappingSchema);

        try {
          const res = id
            ? yield call(service.updateOne, ...extraServiceArgs, id, data)
            : yield call(service.create, ...extraServiceArgs, data);

          if (res.data) {
            yield put({
              type: types().submit.success,
              newEntity: res.data,
              parentId,
            });

            toast.success(
              `${formatName(entity)} ${id ? "updated" : "created"} succesfully`
            );

            onDone(true);
          }
        } catch (e) {
          console.log(e);
          yield put({
            type: types().submit.fail,
            error: e.message,
          });
          onDone(false);

          toast.error(e.validation ? e.validation[0].message : e.message);
        }
      },
    delete: (mappingSchema, isDelete) =>
    function* ({ index, data: unmappedData, extraServiceArgs = [] }) {
        const data = mapDataBySchema(unmappedData, mappingSchema);

        const id = yield select((state) => state[entity][entity][index].id);
        try {
          const res = yield call(
            service.deleteOne,
            ...extraServiceArgs,
            id,
            data
          );

          if (res) {
            yield put({
              type: types().delete.success,
              index,
            });

            const action = isDelete 
              ? "deleted"
              : data.status ? "enabled" : "disabled"

            toast.success(
              `${formatName(entity)} ${action} succesfully`
            );
          }
        } catch (e) {
          console.log(e);
          yield put({
            type: types().delete.fail,
            error: e.message,
          });

          toast.error(e.message);
        }
      },
  });

  return {
    model,
    types,
    actions,
    reducer,
    saga,
  };
};

export default CRUD;
