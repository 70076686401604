import BaseService from "./BaseService";

class EventService extends BaseService {
  getEvents = async (params) => {
    return this.apiGet({
      url: "/schedules",
      data: { params },
    });
  };

  getOne = async (id) =>
    this.apiGet({
      url: `/schedules/${id}`,
    });

  createOne = async (data) =>
    this.apiPost({
      url: "/schedules",
      data,
    });

  updateOne = async (id, data) =>
    this.apiPut({
      url: `/schedules/edit/${id}`,
      data,
    });

  deleteOne = async (id) =>
    this.apiDelete({
      url: `/schedules/${id}`,
    });

  actionOnOne = async (action, id) =>
    this.apiPut({
      url: `/schedules/${action}/${id}`,
    });

  copyEvents = async (events) =>
    this.apiPost({
      url: "/schedules/copy-schedules",
      data: events,
    });
}

export default new EventService();
