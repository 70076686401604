import { takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import LevelService from "../../services/LevelService";

export default function* () {
  yield takeEvery(
    Pagination("orgLevels").types().request,
    Pagination("orgLevels").saga(LevelService.getAll)
  );
  yield takeEvery(
    CRUD("orgLevels").types().submit.request,
    CRUD("orgLevels").saga(LevelService).submit({
      status: (value) => Boolean(value),
    }),
  );
  yield takeEvery(
    CRUD("orgLevels").types().delete.request,
    CRUD("orgLevels").saga(LevelService).delete()
  );
}
