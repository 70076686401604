import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
  useContext,
} from "react";
import Loading from "../components/Loading";
import { useSelector } from "react-redux";
import { get, isArray, keys as iterateKeys } from "lodash";
import PaginationComponent from "../components/Pagination";
import { createSelector } from "reselect";
import { AccessTreeContext } from "..";

const getAccessPath = (keys, root, accessTree) => {
  let accessor = keys;
  accessor = isArray(accessor) ? accessor : accessor.split(".");

  const path = root
    ? isArray(root)
      ? root.concat(accessor)
      : root.split(".").concat(accessor)
    : accessor;

  return get(accessTree, path, false);
};

/**
 * Get keys from the global access tree
 * @param {String | Object} keys - the path or path mapping
 * @param {String | Array} root - the common root path
 * @return {Boolean} the object mapped the same as path mapping or the single value
 */
export const useAccess = (keys, root) => {
  const accessTree = useContext(AccessTreeContext);

  const result = useMemo(
    () =>
      typeof keys === "object" && !isArray(keys)
        ? iterateKeys(keys).reduce((result, currentKey) => {
            result[currentKey] = getAccessPath(
              keys[currentKey],
              root,
              accessTree
            );
            return result;
          }, {})
        : getAccessPath(keys, root, accessTree),
    [keys, accessTree]
  );

  return result;
};

/**
 * Set infiniteScroll to true if you want to use infinite scroll instead of pagination (must also pass ref of scrollable component)
 *
 * getState is a useSelector which should return an object with the following control params from the redux store:
 *
 *    1 - page (the current page number)
 *
 *    2 - total (the total page number)
 *
 *    3 - loading (if the elements are loading)
 *
 *    4 - error (if there were errors in retrival)
 *
 */
export const usePagination = ({
  infiniteScroll,
  disabled,
  ref,
  changeInitial = false,
  scrollPosition = "bottom",
  getState: getStateParent,
  onChange: onChangeParent,
}) => {
  const getState = useCallback(
    (s) => (disabled || !getStateParent ? {} : getStateParent(s)),
    [disabled]
  );

  const { page, pages, items = [], loading, total, error } = useSelector(
    createSelector(getState, (a) => a)
  );
  const done = items.length >= total;
  console.log(items.length, total);
  const onChange = useCallback(
    (page, reset = false) => {
      onChangeParent &&
        onChangeParent(
          {
            page,
            perPage: 10,
            loadedPages: pages ? Object.keys(pages) : null,
          },
          [],
          reset
        );
    },
    [pages]
  );

  const onScroll = useCallback(
    infiniteScroll
      ? ({ target: { scrollTop, scrollHeight, offsetHeight } }) => {
          if (disabled) return;

          const correctScrollTop =
            scrollPosition === "bottom" ? scrollTop : -scrollTop;

          if (
            !done &&
            !loading &&
            scrollHeight - correctScrollTop - offsetHeight <= 5
          ) {
            onChange(page + 1);
          }
        }
      : () => {},
    [done, loading, onChange, disabled, infiniteScroll]
  );

  useEffect(() => {
    changeInitial && onChange(1, true);
  }, [changeInitial, onChange]);

  useEffect(() => {
    if (infiniteScroll) {
      const element = ref.current;
      if (!element) return;

      element.addEventListener("scroll", onScroll);
      return () => element.removeEventListener("scroll", onScroll);
    }
  }, [ref, onScroll, infiniteScroll]);

  useEffect(() => {
    if (infiniteScroll) {
      const element = ref.current;
      disabled && element && element.removeEventListener("scroll", onScroll);
    }
  }, [disabled, ref, onScroll, infiniteScroll]);

  const Pagination = useMemo(
    () => (
      <PaginationComponent
        disabled={disabled}
        active={page}
        onChange={onChange}
        pageCount={total}
      />
    ),
    [page, total, disabled, onChange]
  );

  const LoadingIndicator = useMemo(
    () => (!disabled && !done && !error ? <Loading /> : null),
    [disabled, done, error]
  );

  return Object.assign(
    { error, loading, done },
    infiniteScroll ? { LoadingIndicator } : { Pagination }
  );
};

export const useKeyPress = (
  keyToMatch,
  onPress = () => {},
  bindRef = false
) => {
  const [key, setKey] = useState(keyToMatch ? false : undefined);
  const keyRef = useRef(keyToMatch ? false : undefined);

  const onKeyDown = useCallback(
    ({ key, keyCode }) => {
      const value = keyToMatch ? key === keyToMatch : { key, keyCode };

      if (bindRef) keyRef.current = value;
      else setKey(value);

      if (["INPUT", "TEXTAREA"].includes(document.activeElement.nodeName))
        return;

      keyToMatch === key && onPress();
    },
    [keyToMatch, bindRef]
  );

  const onKeyUp = useCallback(() => {
    const value = keyToMatch ? false : undefined;

    if (bindRef) keyRef.current = value;
    else setKey(value);
  }, [keyToMatch, bindRef]);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [onKeyDown, onKeyUp]);

  return bindRef ? keyRef.current : key;
};

/**
 * Map an object based on a mapping schema
 * @param {Object} mappingSchema
 * @param {Object} data
 */
export const mapDataBySchema = (data = {}, mappingSchema) => {
  if (!mappingSchema) return data;

  return Object.keys(data).reduce(
    (mappedData, property) => {
      if (mappingSchema.hasOwnProperty(property))
        mappedData[property] = mappingSchema[property](data[property], data);

      return mappedData;
    },
    { ...data }
  );
};

export const getSelectOptionValue = (property = {}, value = {}) => {
  return typeof property === "string"
    ? value[property]
    : typeof property === "function"
    ? property(value)
    : property.id;
};

export const getSelectOptionLabel = (property = {}, value = {}) => {
  return typeof property === "string"
    ? value[property]
    : typeof property === "function"
    ? property(value)
    : property.description;
};

export const getIsSelectOptionDisabled = (option, extraCheck) => {
  return (
    (typeof option === "object" &&
      option.hasOwnProperty("status") &&
      !option.status) ||
    (extraCheck ? extraCheck(option) : false)
  );
};

export const generateRandomPassword = (length = 6) =>
  Math.random().toString(36).slice(-length);
