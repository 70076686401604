import { snakeCase, camelCase } from "lodash";
import { call, select, put } from "redux-saga/effects";
import { mapDataBySchema } from "./General";

const typeCase = (entity) => snakeCase(entity).toUpperCase();

const Pagination = (entity) => {
  const types = () => ({
    request: `REQUEST_FETCH_${typeCase(entity)}`,
    success: `FETCH_${typeCase(entity)}_SUCCEEDED`,
    fail: `FETCH_${typeCase(entity)}_FAILED`,
    clear: `REQUEST_CLEAR_${typeCase(entity)}`,
  });

  const model = (pagination = true, perPage = 10) => {
    const model = { error: false, [entity]: [], total: 0, loading: true };

    if (pagination === true)
      model.filters = {
        page: 1,
        perPage,
        sortBy: null,
        search: null,
      };
    else if (pagination === false) model.filters = { pagination: false };
    else if (pagination === null) model.filters = {};

    return model;
  };

  const actions = () => ({
    [camelCase(types().request)]: ["filters", "extraServiceArgs", "reset"],
    [camelCase(types().success)]: null,
    [camelCase(types().fail)]: null,
    [camelCase(types().clear)]: null,
  });

  const reducer = () => ({
    [types().request]: (state) => ({
      ...state,
      [entity]: [],
      error: null,
      loading: true,
    }),
    [types().success]: (
      state,
      { total, filters, reset, infinite, ...rest }
    ) => {
      return {
        ...state,
        loading: false,
        error: null,
        [entity]:
          !reset && infinite
            ? [...state[entity], ...rest[entity]]
            : rest[entity],
        total,
        filters,
      };
    },
    [types().fail]: (state, { error = "" }) => ({
      ...state,
      loading: false,
      error,
    }),
    [types().clear]: (state) => ({
      ...state,
      [entity]: [],
      total: 0,
      filters: null,
      loading: false,
      error: null,
    }),
  });

  const saga = (service, searchProperties, mappingSchema, infinite = false) =>
    function* ({
      filters: userFilters = {},
      extraServiceArgs = [],
      reset = false,
    }) {
      const allFilters = yield select(({ [entity]: { filters } }) => filters);
      const filters = reset ? userFilters : { ...allFilters, ...userFilters };
      const adjustedFilters = { ...filters };

      // console.log(entity, reset, filters, userFilters);

      if (!!adjustedFilters.search && searchProperties) {
        Object.assign(
          adjustedFilters,
          searchProperties.reduce(
            (searchProperties, currentProperty) =>
              (searchProperties[currentProperty] = adjustedFilters.search) &&
              searchProperties,
            {}
          )
        );
        delete adjustedFilters.search;
      }

      if (adjustedFilters.sortBy)
        adjustedFilters.sortBy = {
          value: adjustedFilters.sortBy.desc ? "desc" : "asc",
          name: `orderBy[${adjustedFilters.sortBy.column}]`,
        };

      try {
        const res = yield call(service, ...extraServiceArgs, adjustedFilters);

        if (res.data) {
          const data = res.data.result
            ? mappingSchema
              ? res.data.result.map((data) =>
                  mapDataBySchema(data, mappingSchema)
                )
              : res.data.result
            : [];

          yield put({
            type: types().success,
            infinite,
            reset,
            [entity]: data,
            total: res.data.totalItems,
            filters,
          });
        }
      } catch (e) {
        console.log(e);
        yield put({
          type: types().fail,
          error: e.message,
        });
      }
    };

  return {
    types,
    model,
    actions,
    reducer,
    saga,
  };
};

export default Pagination;
