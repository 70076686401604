import initialState from "../models/locationGroups";
import { createReducer, createActions } from "reduxsauce";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";

const { Types: types, Creators: actions } = createActions({
  ...Pagination("locationGroups").actions(),
  ...CRUD("locationGroups").actions(),
});

export default createReducer(initialState, {
  ...Pagination("locationGroups").reducer(),
  ...CRUD("locationGroups").reducer(),
});

export { types, actions };
