import initialState from "../models/forms";
import { createReducer, createActions } from "reduxsauce";

const { Types: types, Creators: actions } = createActions({
  requestSubmit: ["values"],
  submitSucceeded: null,
  submitFailed: null,
});

export const handleFormState = (state = initialState, action) => {
  const form = action.value ? action.value.form : "";
  const formState = form ? state[form] : {};

  return {
    formState,
    setState: (newState) => (form ? { ...state, [form]: newState } : state),
  };
};

export default createReducer(initialState, {
  [types.REQUEST_SUBMIT]: (state = initialState, action) => {
    const { formState, setState } = handleFormState(state, action);
    return setState({
      ...formState,
      submitting: true,
      validationErrors: null,
      success: false,
    });
  },
  [types.SUBMIT_SUCCEEDED]: (state = initialState, action) => {
    const { formState, setState } = handleFormState(state, action);
    return setState({
      ...formState,
      submitting: false,
      validationErrors: null,
      success: true,
    });
  },
  [types.SUBMIT_FAILED]: (state = initialState, action) => {
    const { formState, setState } = handleFormState(state, action);
    return setState({
      ...formState,
      submitting: false,
      success: false,
      validationErrors: action.value.validationErrors,
    });
  },
});

export { types, actions };
