import { paginationStartIndex } from "../../config";

export default {
  posts: {
    posts: [],
    page: paginationStartIndex,
    loading: false,
    error: false,
    calling: false,
  },
};
