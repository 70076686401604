import paginated from "./paginated";
import authentication from "./authentication";
import profile from "./profile";
import people from "./people";
import locations from "./locations";
import notifications from "./notifications";
import posts from "./posts";
import comments from "./comments";

export default {
  authentication,
  paginated,
  profile,
  people,
  notifications,
  locations,
  posts,
  comments,
};
