import initialState from "../models/people";
import { createReducer, createActions } from "reduxsauce";
import Pagination from "../../helpers/Pagination";
import { cloneDeep } from "lodash";

const { Types: types, Creators: actions } = createActions({
  ...Pagination("people").actions(),
  requestDeleteUser: ["index"],
  deleteUserSucceeded: null,
  deleteUserFailed: null,
  // user form
  setFormVisibility: ["state"],
  setFormError: ["error"],
  requestSetActive: ["active"],
  requestSetValues: ["values", "id", "isProfile"],
  requestSubmitUser: ["configs"],
  submitUserSucceeded: ["user", "id"],
  submitUserFailed: ["error"],
  requestValidateGroup: ["group"],
  requestResetForm: null,
  requestValidateStep: ["nextStep", "values", "shouldSubmit"],
  validateStepSucceeded: ["nextStep"],
  validateStepFailed: ["error"],
});

export default createReducer(initialState, {
  ...Pagination("people").reducer(),
  [types.REQUEST_DELETE_USER]: (state) => ({
    ...state,
    error: false,
    loading: true,
  }),
  [types.DELETE_USER_SUCCEEDED]: (state, { id }) => ({
    ...state,
    loading: false,
    error: false,
    people: state.people.filter(({ id: i }) => i !== id),
  }),
  [types.DELETE_USER_FAILED]: (state, { error = "" }) => ({
    ...state,
    loading: false,
    error,
  }),

  // user form
  [types.SET_FORM_VISIBILITY]: ({ form, ...state }, { state: isOpen }) => ({
    ...state,
    form: { ...form, isOpen },
  }),
  [types.REQUEST_SET_ACTIVE]: ({ form, ...state }, { active }) => ({
    ...state,
    form: { ...form, error: null, active },
  }),
  [types.REQUEST_SET_VALUES]: (
    { form: { values: existingValues, ...form }, ...state },
    { values: newValues, id, isProfile = false }
  ) => {
    const newMappedValues = Object.keys(existingValues).reduce(
      (obj, step) => Object.assign(obj, { [step]: {} }),
      {}
    );

    for (const field in newValues)
      for (const step in existingValues)
        if (existingValues[step].hasOwnProperty(field))
          newMappedValues[step][field] = newValues[field];

    return {
      ...state,
      form: { ...form, isOpen: true, id, isProfile, values: newMappedValues },
    };
  },
  [types.REQUEST_VALIDATE_STEP]: ({ form, ...state }) => ({
    ...state,
    form: {
      ...form,
      error: null,
      validating: true,
    },
  }),
  [types.VALIDATE_STEP_SUCCEEDED]: (
    { form: { active, values, ...form }, ...state },
    { nextStep, values: newValues }
  ) => ({
    ...state,
    form: {
      ...form,
      error: null,
      validating: false,
      active: nextStep,
      values: {
        ...values,
        [Object.keys(values)[active]]: { ...newValues },
      },
    },
  }),
  [types.VALIDATE_STEP_FAILED]: ({ form, ...state }, { error }) => ({
    ...state,
    form: { ...form, validating: false, error },
  }),
  [types.SET_FORM_ERROR]: ({ form, ...state }, { error }) => ({
    ...state,
    form: { ...form, error },
  }),
  [types.REQUEST_RESET_FORM]: (state) => ({
    ...state,
    form: cloneDeep(initialState.form),
  }),
  [types.REQUEST_SUBMIT_USER]: ({ form, ...state }) => ({
    ...state,
    form: {
      ...form,
      submitting: true,
    },
  }),
  [types.SUBMIT_USER_SUCCEEDED]: (
    { form, people, ...state },
    { user, id }
  ) => ({
    ...state,
    people: !!id
      ? people.map((currentUser) =>
          id === currentUser.id
            ? Object.assign({ ...currentUser }, user)
            : currentUser
        )
      : [...people, user],
    form: {
      ...form,
      submitting: false,
      validating: false,
    },
  }),
  [types.SUBMIT_USER_FAILED]: ({ form, ...state }, { error }) => ({
    ...state,
    form: {
      ...form,
      submitting: false,
      validating: false,
      error,
    },
  }),
});

export { types, actions };
