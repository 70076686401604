export default {
  authenticated: false,
  requiredToChangePassword: false,
  user: undefined,
  checking: true,
  loggingIn: false,
  loggingOut: false,
  loading: false,
  error: null,
  accessTree: {},
  forgotPassword: {
    loading: false,
    error: false,
    success: false,
    successConfirm: false,
    confirmationToken: null,
  },
  changePassword: {
    loading: false,
    error: false,
    success: false,
  },
};
