import initialState from "../models/messages";
import { createReducer, createActions } from "reduxsauce";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";

const { Types: types, Creators: actions } = createActions({
  ...Pagination("messages").actions(),
  ...CRUD("messages").actions(),
  fetchMoreMessages: ["page"],
  fetchMoreMessagesSucceeded: null,
  fetchMoreMessagesFailure: null,
});

export default createReducer(initialState, {
  ...Pagination("messages").reducer(),
  ...CRUD("messages").reducer(),
  [types.FETCH_MORE_MESSAGES]: (state) => ({
    ...state,
    error: null,
    loading: true,
  }),
  [types.FETCH_MORE_MESSAGES_SUCCEEDED]: (
    { 
      filters, 
      messages, 
      ...rest 
    }, { 
      messages: newMessages, 
      page 
    }
  ) => ({
    ...rest,
    filters: {
      ...filters,
      page,
    },
    messages: [...messages, ...newMessages],
    error: null,
    loading: false,
  }),
  [types.FETCH_MORE_MESSAGES_FAILURE]: (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }),
  [types.SUBMIT_MESSAGES_SUCCEEDED]: ({ messages, ...state }, { newEntity }) => ({
    ...state,
    messages: [newEntity, ...messages],
  }),
});

export { types, actions };
