import { createReducer, createActions } from "reduxsauce";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import initialState from "../models/notifications";

const { Types: types, Creators: actions } = createActions({
  ...Pagination("notifications").actions(),
  ...CRUD("notifications").actions(),
  requestFetchMoreNotifications: ["page"],
  fetchMoreNotificationsSucceeded: null,
  fetchMoreNotificationsFailed: null,
  requestToggleNotificationRead: ["id", "readStatus"],
  toggleNotificationReadSucceeded: null,
  toggleNotificationReadFailed: null,
});

export default createReducer(initialState, {
  ...Pagination("notifications").reducer(),
  ...CRUD("notifications").reducer(),
  [types.FETCH_MORE_NOTIFICATIONS_SUCCEEDED]: ({ filters, ...rest }, { notifications, totalItems, page }) => ({
    ...rest,
    notifications: rest.notifications.concat(notifications),
    total: totalItems,
    filters: {
      ...filters,
      page,
    },
  }),
  [types.FETCH_MORE_NOTIFICATIONS_FAILED]: (state, { error }) => ({
    ...state,
    error,
  }),
  [types.TOGGLE_NOTIFICATION_READ_SUCCEEDED]: (state, { id }) => ({
    ...state,
    notifications: state.notifications.map(
      (notification) => notification.id === id 
        ? ({ ...notification, readStatus: !notification.readStatus })
        : notification
    ),
  }),
  [types.TOGGLE_NOTIFICATION_READ_FAILED]: (state, { error }) => ({
    ...state,
    error,
  }),
});

export { types, actions };
