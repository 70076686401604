import { upperCase } from "lodash";
import moment from "moment";
import { FILTERS } from "../../components/Scheduler/constants";
import { schedulerModes } from "../../config";

// const mode = "day";
// const mode = "week";
const mode = "month";

export default {
  date: moment().startOf(mode),
  mode: schedulerModes[upperCase(mode)],
  selectedDate: undefined,
  error: undefined,
  loadingEvents: [],
  dimensions: {
    MINUTE_WIDTH: 1,
  },
  monthEvents: {
    events: {},
    extras: {},
    loading: true,
    error: false,
  },
  userEvents: {
    users: [],
    page: 1,
    perPage: 10,
    events: {},
    total: 0,
    loading: true,
    error: false,
  },
  selectedEvents: [],
  dragging: { started: false },
  resizing: { started: false },
  eventDetails: { open: false },
  // panels and modals
  eventPanel: {
    open: false,
    submitting: false,
    error: null,
  },
  filtersPanel: {
    open: false,
    filters: {
      sortBy: {
        field: FILTERS.SORT_BY.NAME,
        order: "asc",
      },
      [FILTERS.IS_SELF]: true,
    },
  },
  dateEvents: { open: false, date: null, id: null, events: [] },
};
