import initialState from "../models/leaves";
import { createReducer, createActions } from "reduxsauce";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";

const { Types: types, Creators: actions } = createActions({
  ...Pagination("leaves").actions(),
  ...CRUD("leaves").actions(),
  requestActionOnLeave: ["id", "action"],
  actionOnLeaveSucceeded: ["leave"],
  actionOnLeaveFailed: ["error"],
});

export default createReducer(initialState, {
  ...Pagination("leaves").reducer(),
  ...CRUD("leaves").reducer(),
  [types.REQUEST_ACTION_ON_LEAVE]: (state, action) => ({ ...state }),
  [types.ACTION_ON_LEAVE_SUCCEEDED]: ({ leaves, ...state }, { event }) => ({
    ...state,
    leaves: leaves.map((leave) => (leave.id === event.id ? event : leave)),
  }),
  [types.ACTION_ON_LEAVE_FAILED]: (state, { error }) => ({
    ...state,
    error,
  }),
});

export { types, actions };
