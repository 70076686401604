import { takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import LevelRecordService from "../../services/LevelRecordService";

export default function* () {
  yield takeEvery(
    Pagination("orgLevelsRecords").types().request,
    Pagination("orgLevelsRecords").saga(LevelRecordService.getAll, [
      "code",
      "description",
    ])
  );
  yield takeEvery(
    CRUD("orgLevelsRecords").types().submit.request,
    CRUD("orgLevelsRecords")
      .saga(LevelRecordService)
      .submit({
        parent: (values) => Array.isArray(values) 
          ? values.map(({ id }) => id) 
          : [],
      })
  );
  yield takeEvery(
    CRUD("orgLevelsRecords").types().delete.request,
    CRUD("orgLevelsRecords").saga(LevelRecordService).delete({
        parent: (values) => Array.isArray(values) 
          ? values.map(({ id }) => id) 
          : [],
      })
  );
}
