export const mapReceivedJobsAndLocations = (value) =>
  value && Array.isArray(value)
    ? value
        .filter((entity) => entity && entity.id)
        .map(({ data = {}, ...entity }) => ({ ...entity, ...data }))
    : [];

export const getUserDisplayName = (user = {}) =>
  `${user.firstName || ""} ${user.middleName ? user.middleName + " " : ""}${
    user.lastName || ""
  }`;
