import { takeEvery, takeLatest, call, select, put } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import NotificationService from "../../services/NotificationService";
import { types } from "../reducers/notifications";

function* toggleNotificationRead({ id, readStatus }) {
  try {
    if (readStatus === true) {
      yield call(NotificationService.markAsUnread, id);
    } else {
      yield call(NotificationService.markAsRead, id);
    }

    yield put({
      type: types.TOGGLE_NOTIFICATION_READ_SUCCEEDED,
      id,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: types.TOGGLE_NOTIFICATION_READ_FAILED,
      error: e.message,
    });
  }
}

export default function* () {
  yield takeEvery(
    Pagination("notifications").types().request,
    Pagination("notifications").saga(NotificationService.getAll)
  );
  yield takeEvery(
    types.REQUEST_TOGGLE_NOTIFICATION_READ,
    toggleNotificationRead
  );
}
