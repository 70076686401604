import BaseService from "./BaseService";

class LeaveService extends BaseService {
  getAll = async (filters) =>
    this.apiGetWithFilters({ url: "/leaves" }, filters);

  getOne = async (id) =>
    this.apiGet({
      url: `/leaves/${id}`,
    });

  createOne = async (data) =>
    this.apiPost({
      url: "/leaves",
      data,
    });

  updateOne = async (id, data) =>
    this.apiPut({
      url: `/leaves/${id}`,
      data,
    });

  deleteOne = async (id) =>
    this.apiDelete({
      url: `/leaves/${id}`,
    });

  actionOnOne = async (action, id) =>
    this.apiPut({
      url: `/leaves/${action}/${id}`,
    });
}

export default new LeaveService();
