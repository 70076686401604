import { createReducer, createActions } from "reduxsauce";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";

function recordMapper(newRecord, id, record) {
  const isChangedRecordParent = record.parent
    .find((parent) => parent.id === id) !== -1;

  if (record.id === id) return newRecord;

  if (!isChangedRecordParent) return record;

  return {
    ...record,
    parent: record.parent.map((parent) => 
      parent.id === id ? newRecord : parent
    ),
  };
}

function generateNewRecords({ records, newRecord, id }) {
  const mapperFunction = recordMapper.bind(null, newRecord, id);

  if (id) return records.map(mapperFunction);
  else return records.concat(newRecord);
};

const initialState = {
  ...Pagination("orgLevelsRecords").model(),
  ...CRUD("orgLevelsRecords").model(),
};

const { Types: types, Creators: actions } = createActions({
  ...Pagination("orgLevelsRecords").actions(),
  ...CRUD("orgLevelsRecords").actions(),
});

export default createReducer(initialState, {
  ...Pagination("orgLevelsRecords").reducer(),
  ...CRUD("orgLevelsRecords").reducer(),
  SUBMIT_ORG_LEVELS_RECORDS_SUCCEEDED: (
    { id, ...rest },
    { newEntity, parentId }
  ) => ({
    ...rest,
    submitting: false,
    error: null,
    orgLevelsRecords: generateNewRecords({ 
      records: rest.orgLevelsRecords, 
      newRecord: newEntity, 
      id: parentId || id,
    }),
  }),
});

export { types, actions };
