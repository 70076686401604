import { takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import UserRolesService from "../../services/UserRolesService";

export default function* () {
  yield takeEvery(
    Pagination("userRoles").types().request,
    Pagination("userRoles").saga(UserRolesService.getAll, ["name"])
  );
  yield takeEvery(
    CRUD("userRoles").types().submit.request,
    CRUD("userRoles").saga(UserRolesService).submit({
      status: (value) => Boolean(value),
    })
  );
  yield takeEvery(
    CRUD("userRoles").types().delete.request,
    CRUD("userRoles").saga(UserRolesService).delete()
  );
}
