import { schedulerModes } from "../../../config";
import {
  initializeMonthEvents,
  initializeUserEvents,
  mapEvents,
  remapDateEvents,
} from "../../../helpers/Events";

export const actions = {
  startFetchingEvents: ["mapping"],
  fetchEvents: ["value"],
  fetchEventsFailed: ["error"],
  fetchMonthEventsSucceeded: ["events", "extras"],
  fetchMonthEventsFailed: ["error"],
  initializeMonthEvents: ["dates"],
  fetchDateEventsSucceeded: ["events", "total", "page"],
  fetchDateEventsFailed: ["error"],
  initializeUserEvents: ["users", "dates", "page", "total"],
  fetchUserEventsSucceeded: ["events", "users"],
  fetchUserEventsFailed: ["error"],
  requestExtraDayEvents: ["value"],
};

export default (types) => ({
  [types.START_FETCHING_EVENTS]: (state, { mapping }) => ({
    ...state,
    [`${mapping}Events`]: {
      ...state[`${mapping}Events`],
      loading: true,
      error: false,
    },
  }),

  [types.FETCH_EVENTS]: (state, { value }) => {
    let mapping;

    if (value) mapping = value.mapping;
    else {
      switch (state.mode) {
        case schedulerModes.WEEK:
        case schedulerModes.DAY:
          mapping = "user";
          break;

        case schedulerModes.MONTH:
          mapping = "month";
          break;

        default:
          break;
      }
    }

    return {
      ...state,
      [`${mapping}Events`]: {
        ...state[`${mapping}Events`],
        loading: true,
        error: false,
      },
    };
  },
  [types.FETCH_EVENTS_FAILED]: (state, { mapping, error }) => ({
    ...state,
    [`${mapping}Events`]: {
      ...state[`${mapping}Events`],
      loading: false,
      error,
    },
  }),

  // month events
  [types.INITIALIZE_MONTH_EVENTS]: ({ monthEvents, ...state }, { dates }) => ({
    ...state,
    monthEvents: {
      ...monthEvents,
      loading: true,
      events: initializeMonthEvents(dates),
    },
  }),
  [types.FETCH_MONTH_EVENTS_SUCCEEDED]: (
    { monthEvents: { events: oldEvents, ...monthEvents }, ...state },
    { events, extras }
  ) => ({
    ...state,
    monthEvents: {
      ...monthEvents,
      loading: false,
      error: false,
      events: mapEvents(oldEvents, { add: events }),
      extras,
    },
  }),
  [types.FETCH_MONTH_EVENTS_FAILED]: (
    { monthEvents, ...state },
    { error }
  ) => ({
    ...state,
    monthEvents: {
      ...monthEvents,
      loading: false,
      error,
    },
  }),

  // date events (single date)
  [types.FETCH_DATE_EVENTS_SUCCEEDED]: (
    { dateEvents: { events: oldSingleDateEvents, ...dateEvents }, ...state },
    { events: receivedEvents, total, page }
  ) => ({
    ...state,
    dateEvents: {
      ...dateEvents,
      loading: false,
      error: false,
      total,
      events: remapDateEvents(oldSingleDateEvents, { add: receivedEvents }),
      page,
    },
  }),
  [types.FETCH_DATE_EVENTS_FAILED]: ({ dateEvents, ...state }, { error }) => ({
    ...state,
    dateEvents: {
      loading: false,
      error,
    },
  }),
  [types.REQUEST_EXTRA_DAY_EVENTS]: ({ dateEvents, ...state }, { value }) => ({
    ...state,
    dateEvents: {
      ...dateEvents,
      ...value,
    },
  }),

  // user events (daily and weekly)
  [types.INITIALIZE_USER_EVENTS]: (
    { userEvents, ...state },
    { users, dates, page, total }
  ) => ({
    ...state,
    userEvents: {
      ...userEvents,
      error: false,
      loading: true,
      total,
      page,
      events: initializeUserEvents(users, dates),
      users,
    },
  }),
  [types.FETCH_USER_EVENTS_SUCCEEDED]: (
    { userEvents: { events: oldEvents, ...userEvents }, ...state },
    { events, users, dates }
  ) => ({
    ...state,
    userEvents: {
      ...userEvents,
      users,
      error: false,
      loading: false,
      events: mapEvents(
        { ...oldEvents, ...initializeUserEvents(users, dates) },
        { add: events },
        true
      ),
    },
  }),
  [types.FETCH_USER_EVENTS_FAILED]: ({ userEvents, ...state }, { error }) => ({
    ...state,
    userEvents: {
      ...userEvents,
      loading: false,
      error,
    },
  }),
});
