import { call, put, takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import PostService from "../../services/PostService";
import { types } from "../reducers/posts";

export default function* () {
  yield takeEvery(
    Pagination("posts").types().request,
    Pagination("posts").saga(PostService.getAll, undefined, undefined, true)
  );
  yield takeEvery(
    CRUD("posts").types().submit.request,
    CRUD("posts").saga(PostService).submit()
  );
  yield takeEvery(
    CRUD("posts").types().delete.request,
    CRUD("posts").saga(PostService).delete(null, true)
  );
  yield takeEvery(types.REQUEST_LIKE_POST, requestLikePost);
}

function* requestLikePost({ id, liked }) {
  try {
    yield call(
      liked ? PostService.requestUnlikePost : PostService.requestLikePost,
      id
    );

    yield put({ type: types.LIKE_POST_SUCCEEDED, id });
  } catch (error) {
    console.log(error);
    yield put({ type: types.LIKE_POST_FAILED, error });
  }
}
