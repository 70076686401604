import { takeEvery, put, call } from "redux-saga/effects";
import { types } from "../reducers/leaves";
import LeaveService from "../../services/LeaveService";
import Pagination from "../../helpers/Pagination";

export default function* () {
  yield takeEvery(
    Pagination("leaves").types().request,
    Pagination("leaves").saga(LeaveService.getAll)
  );

  yield takeEvery(types.REQUEST_ACTION_ON_LEAVE, requestActionOnLeave);
}

function* requestActionOnLeave({ id, action }) {
  try {
    const res = call(LeaveService.actionOnOne, action, id);

    console.log(res.data);

    yield put({
      type: types.ACTION_ON_LEAVE_SUCCEEDED,
      id,
    });
  } catch (error) {
    yield put({
      type: types.ACTION_ON_LEAVE_FAILED,
      error,
    });
  }
}
