import CRUDService from "./CRUDService";

class PostService extends CRUDService {
  withFilters = true;
  entity = "posts";
  isDeleteDeactivate = false;

  requestLikePost = async (id) =>
    this.apiPut({
      url: `${this.getUrl()}/like/${id}`,
    });

  requestUnlikePost = async (id) =>
    this.apiPut({
      url: `${this.getUrl()}/unlike/${id}`,
    });

  getPostComments = async (postId, filters) => 
    this.apiGetWithFilters({
      url: `${this.getUrl()}/comments/${postId}`,
    }, filters);

  requestCommentOnPost = async (postId, comment) =>
    this.apiPost({
      url: `${this.getUrl()}/comments/${postId}`,
      data: comment,
    });

  requestDeleteComment = async (postId, commentId) =>
    this.apiDelete({
      url: `${this.getUrl()}/comments/${postId}/${commentId}`,
    });
}

export default new PostService();
