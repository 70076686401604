import { takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import EarningCodeService from "../../services/EarningCodeService";

export default function* () {
  yield takeEvery(
    Pagination("earningCodes").types().request,
    Pagination("earningCodes").saga(EarningCodeService.getAll)
  );
  yield takeEvery(
    CRUD("earningCodes").types().submit.request,
    CRUD("earningCodes").saga(EarningCodeService).submit({
      status: (value) => Boolean(value),
    })
  );
  yield takeEvery(
    CRUD("earningCodes").types().delete.request,
    CRUD("earningCodes").saga(EarningCodeService).delete()
  );
}
