import CRUDService from "./CRUDService";

class LocalizationService extends CRUDService {
  entity = "locales";
  withFilters = true;

  getDefaultSchema = () => {
    return this.apiGet({
      url: "/default-locale",
    });
  };
}

export default new LocalizationService();
