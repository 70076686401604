import { takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import JobService from "../../services/JobService";
import CRUD from "../../helpers/CRUD";

export default function* () {
  yield takeEvery(
    Pagination("jobs").types().request,
    Pagination("jobs").saga(JobService.getAll, ["code", "description"])
  );
  yield takeEvery(
    CRUD("jobs").types().submit.request,
    CRUD("jobs").saga(JobService).submit({
      status: (value) => Boolean(value),
      group: (value) => {
        if (!value) return undefined;
        else return value.id;
      },
    })
  );
  yield takeEvery(
    CRUD("jobs").types().delete.request,
    CRUD("jobs").saga(JobService).delete()
  );
}
