import React, { useMemo, useCallback, useEffect } from "react";
import Button from "../Button";
import clsx from "clsx";

function Pagination({
  total = 1,
  frameSize = 10,
  page: active = 1,
  pageSize = 10,
  onChange: onChangeParent,
  changeInitial = false,
}) {
  const onChange = useCallback(onChangeParent ? onChangeParent : () => {}, [
    onChangeParent,
  ]);

  const totalPages = Math.ceil(total / pageSize);

  const pages = useMemo(() => {
    const pages = [],
      frames = Math.ceil(totalPages / frameSize),
      start = Math.floor((active - 1) / frameSize) * frameSize + 1,
      currentFrame = Math.ceil(start / frameSize),
      end =
        totalPages === 0
          ? 2
          : start +
            (currentFrame === frames
              ? totalPages - (frames - 1) * frameSize
              : frameSize);

    for (let i = start; i < end; i++)
      pages.push(
        <Button
          onClick={() => active !== i && onChange(i)}
          bordered
          disabled={[0, 1].includes(totalPages)}
          className={clsx(active === i && "active")}
          filled={active}
          color={active === i ? "primary" : undefined}
          key={i}
        >
          {i}
        </Button>
      );

    if (currentFrame > 1)
      pages.unshift(
        <Button
          onClick={() => onChange((currentFrame - 1) * frameSize)}
          bordered
          className="nav"
          key="extra-left"
        >
          ...
        </Button>
      );

    if (frames - currentFrame > 0)
      pages.push(
        <Button
          onClick={() => onChange(currentFrame * frameSize + 1)}
          bordered
          className="nav"
          key="extra-right"
        >
          ...
        </Button>
      );

    return pages;
  }, [total, active, frameSize, onChange]);

  useEffect(() => {
    changeInitial && onChange(1);
  }, []);

  return (
    <div className="pagination">
      <Button
        onClick={() => active > 1 && onChange(active - 1)}
        bordered
        disabled={active === 1 || totalPages === 0}
        className="nav"
        icon="chevron-left"
      />
      {pages}
      <Button
        disabled={active === totalPages || totalPages === 0}
        onClick={() => active < totalPages && onChange(active + 1)}
        bordered
        className="nav"
        icon="chevron-right"
      />
    </div>
  );
}

export default Pagination;
