import initialState from "../models/profile";
import { createReducer, createActions } from "reduxsauce";

const { Types: types, Creators: actions } = createActions({
  requestChangeProfilePicture: ["image"],
  changeProfilePictureSucceeded: null,
  changeProfilePictureFailed: ["error"],
});

export default createReducer(initialState, {
  [types.REQUEST_CHANGE_PROFILE_PICTURE]: (state = initialState) => ({
    ...state,
    savingProfilePicture: true,
  }),
  [types.CHANGE_PROFILE_PICTURE_SUCCEEDED]: (state = initialState) => ({
    ...state,
    savingProfilePicture: true,
    error: null,
  }),
  [types.CHANGE_PROFILE_PICTURE_FAILED]: (state = initialState, { error }) => ({
    ...state,
    savingProfilePicture: true,
    error,
  }),
});

export { types, actions };
