import { takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import LocalizationService from "../../services/LocalizationService";

export default function* () {
  yield takeEvery(
    Pagination("locales").types().request,
    Pagination("locales").saga(LocalizationService.getAll)
  );

  yield takeEvery(
    CRUD("locales").types().submit.request,
    CRUD("locales").saga(LocalizationService).submit()
  );
}
