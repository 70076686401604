import Pagination from "../../helpers/Pagination";

export default {
  error: false,
  ...Pagination("people").model(),
  form: {
    isOpen: false,
    active: 0,
    id: null,
    submitting: false,
    validating: false,
    error: null,
    isProfile: false,
    values: {
      start: {
        ssn: "",
        confirmSsn: "",
        firstName: "",
        middleName: "",
        lastName: "",
        employeeNumber: "",
        hiringDate: "",
        username: "",
        plainPassword: "",
        userRole: "",
        // company: "",
        status: "",
        birthDate: "",
      },
      personal: {
        address: "",
        addressLine2: "",
        city: "",
        stateOrProvince: "",
        zipOrPostalCode: "",
        country: "",
        personalPhone: "",
        workPhone: "",
        email: "",
        workEmail: "",
        gender: "",
      },
      locationLevels: {
        locations: [],
        level1: "",
        level2: "",
        level3: "",
        level4: "",
      },
      jobs: {
        supervisor: "",
        employeeType: "",
        hourlyOrSalaried: "",
        fullOrPartTime: "",
        payGroup: "",
        jobs: [],
      },
    },
  },
};
