import clsx from "clsx";
import React from "react";

function Badge({ children, className, color, size = "md" }) {
  return (
    <div className={clsx("badge", className, color, size)}>{children}</div>
  );
}

export default Badge;
