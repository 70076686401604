import { mapChangedEventByView } from "../../../helpers/Events";

export const actions = {
  // details
  requestEventDetails: ["eventDetails"],
  // event change
  requestEventChange: ["value"],
  eventChangeSucceeded: ["value"],
  eventChangeFailed: ["error"],
  // selecting
  requestSelectEvent: ["id"],
  requestAddEventToSelection: ["id"],
  requestDeselectEvent: ["id"],
  requestDeselectAllEvents: null,
};

const addEventToList = (list = [], event) => 
  list.includes(event.id) ? list : list.concat(event.id);

const addEventsListToList = (list = [], events) => 
  events.reduce(addEventToList, list);

export const addEventsToList = (list = [], events) =>
  Array.isArray(events) 
    ? addEventsListToList(list, events)
    : addEventToList(list, events);

export const removeEventsFromList = (list = [], events) =>
  Array.isArray(events)
    ? list.filter((loadingEvent) => events.every(
        (event) => loadingEvent !== event.id
      ))
    : list.filter((loadingEvent) => loadingEvent !== events.id);

export default (types) => ({
  [types.REQUEST_EVENT_DETAILS]: (state, { eventDetails }) => ({
    ...state,
    eventDetails,
  }),

  [types.REQUEST_EVENT_CHANGE]: (
    { loadingEvents, ...state },
    { value: { oldEvent } },
  ) => ({
    ...state,
    loadingEvents: addEventsToList(loadingEvents, oldEvent),
  }),
  [types.EVENT_CHANGE_SUCCEEDED]: (
    { loadingEvents, ...state },
    { value: { newEvent, oldEvent, toView: view } },
  ) => ({
    ...state,
    loadingEvents: removeEventsFromList(loadingEvents, newEvent),
    ...(oldEvent &&
      newEvent &&
      mapChangedEventByView({
        state,
        view,
        newEvent,
        oldEvent,
      })),
  }),
  [types.EVENT_CHANGE_FAILED]: (
    { loadingEvents, ...state }, 
    { error, oldEvent },
  ) => ({
    ...state,
    loadingEvents: removeEventsFromList(loadingEvents, oldEvent),
    error,
  }),

  // selecting
  [types.REQUEST_SELECT_EVENT]: (state, { id }) => ({
    ...state,
    selectedEvents: [id],
  }),
  [types.REQUEST_ADD_EVENT_TO_SELECTION]: (
    { selectedEvents, ...state },
    { id }
  ) => ({
    ...state,
    selectedEvents: [...selectedEvents, id],
  }),
  [types.REQUEST_DESELECT_EVENT]: ({ selectedEvents, ...state }, { id }) => ({
    ...state,
    selectedEvents: selectedEvents.filter((i) => i !== id),
  }),
  [types.REQUEST_DESELECT_ALL_EVENTS]: (state) =>
    state.selectedEvents.length > 0
      ? {
          ...state,
          selectedEvents: [],
        }
      : state,
});
