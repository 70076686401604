import { createReducer, createActions } from "reduxsauce";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";

const initialState = {
  ...Pagination("budgets").model(false),
  ...CRUD("budgets").model(),
};

const { Types: types, Creators: actions } = createActions({
  ...Pagination("budgets").actions(),
  ...CRUD("budgets").actions(),
});

export default createReducer(initialState, {
  ...Pagination("budgets").reducer(),
  ...CRUD("budgets").reducer(),
});

export { types, actions };
