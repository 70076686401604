import React from "react";
import { toast } from "react-toastify";
import Button from "../components/Button";

const ToastWithActions = ({ closeToast, message, actions }) => {
  return (
    <div>
      <div className="message">{message}</div>
      <div className="actions">
        {actions.map(({ name, onClick, ...rest }, i) => (
          <Button filled key={i} onClick={() => onClick(closeToast)} {...rest}>
            {name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export const confirmToast = (
  whatToConfirm,
  onConfirm = () => {},
  onCancel = () => {}
) =>
  toast(
    <ToastWithActions
      message={`Are you sure you want to ${whatToConfirm}?`}
      actions={[
        {
          name: "No",
          color: "red",
          onClick: (c) => {
            c();
            onCancel();
          },
        },
        {
          name: "Yes",
          color: "green",
          onClick: (c) => {
            c();
            onConfirm();
          },
        },
      ]}
    />,
    {
      className: "toast-with-actions",
      position: "top-center",
      autoClose: false,
      draggable: false,
      closeOnClick: false,
    }
  );

export const toastWithActions = (message, actions, toastConfig = {}) =>
  toast(<ToastWithActions message={message} actions={actions} />, {
    autoClose: false,
    draggable: false,
    closeOnClick: false,
    ...toastConfig,
  });
