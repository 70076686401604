import { takeEvery, put, select, delay, call } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import MessageService from "../../services/MessageService";
import moment from "moment";
import { types } from "../reducers/messages";

const generateString = (length) =>
  [...Array(length)].map(() => Math.random().toString(36)[2]).join("");

const getRandomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const generateUser = () => ({
  id: generateString(5),
  firstName: generateString(3),
  lastName: generateString(4),
  middleName: "",
});

export function generateMessage(date = moment(), user = generateUser()) {
  const isNewDay = Math.random() < 0.1;
  const createdAt = date.subtract(
    getRandomNumber(0, 3),
    isNewDay ? "day" : "hour"
  );

  return {
    id: generateString(5),
    body: generateString(getRandomNumber(4, 35)),
    createdAt: createdAt.toDate(),
    createdBy: user,
    attachments: [],
    read: true,
  };
}

function* generateMessages(count = 1, startDate = new Date()) {
  const date = moment(startDate);

  const users = yield select(
    ({ conversations: { conversations } }) =>
      conversations.find((conversation) => conversation.id === conversations.id)
        ?.users
  );

  return [...new Array(count)].map(() =>
    generateMessage(date, users[getRandomNumber(0, users.length)])
  );
}

function* temporaryMessageGetter({ filters: newFilters }) {
  try {
    const filters = yield select(({ messages }) => messages.filters);

    const messages = yield generateMessages(filters.perPage);

    yield put({
      type: Pagination("messages").types().success,
      messages,
      total: filters.perPage * 3,
      filters: {
        ...filters,
        page: 1,
        selectedConversation: newFilters?.selectedConversation,
      },
    });
  } catch (e) {
    console.log(e);
  }
}

function* temporaryMoreMessageGetter(args) {
  try {
    yield delay(500);

    const { lastMessage, perPage } = yield select(
      ({
        messages: {
          messages,
          filters: { perPage },
        },
      }) => ({ lastMessage: messages[messages.length - 1], perPage })
    );

    const messages = yield generateMessages(perPage, lastMessage.createdAt);

    yield put({
      type: types.FETCH_MORE_MESSAGES_SUCCEEDED,
      messages,
      page: 1,
    });
  } catch (e) {
    console.log(e);
  }
}

function* fetchMessages({ filters: newFilters }) {
  try {
    const { selectedConversationId } = newFilters;

    const filters = yield select(({ messages }) => messages.filters);

    const { data } = yield call(MessageService.getAll, selectedConversationId);
    const { result, totalItems } = data;

    yield put({
      type: types.FETCH_MESSAGES_SUCCEEDED,
      total: totalItems,
      messages: result,
      filters,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: types.FETCH_MESSAGES_FAILURE,
      error,
    });
  }
}

function* submitMessage({ value: { data, onDone = () => {} } }) {
  try {
    const res = yield call(MessageService.create, data);

    yield put({
      type: types.SUBMIT_MESSAGES_SUCCEEDED,
      newEntity: res.data,
    });

    onDone(true);
  } catch (e) {
    console.log(e);
    yield put({
      type: types.SUBMIT_MESSAGES_FAILURE,
      error: e.message,
    });
    onDone(false);
  }
}

export default function* () {
  yield takeEvery(types.FETCH_MORE_MESSAGES, temporaryMoreMessageGetter);
  yield takeEvery(Pagination("messages").types().request, fetchMessages);
  yield takeEvery(
    CRUD("messages").types().submit.request,
    // CRUD("messages").saga(MessageService).submit()
    submitMessage
  );
  yield takeEvery(
    CRUD("messages").types().delete.request,
    CRUD("messages").saga(MessageService).delete()
  );
}
