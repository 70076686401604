import { cloneDeep, set, update } from "lodash";
import moment from "moment";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { leaveEventTypeName, schedulerModes } from "../config";
import { mapDataBySchema } from "./General";
import { getUserDisplayName } from "./Users";

export const getEventColor = (event) => {
  const { typeColor, status, typeName } = event;

  switch (status) {
    case "pending":
    case "rejected":
      return typeName === leaveEventTypeName ? typeColor : status;

    default:
      return typeColor;
  }
};

export const getNewStatus = (action) => {
  switch (action) {
    case "release":
      return "released";
    case "approve":
      return "approved";
    case "reject":
      return "rejected";
    case "submit":
      return "submitted";

    default:
      return action;
  }
};

export const mapSubmittedEventData = (submittedData) =>
  mapDataBySchema(submittedData, {
    startDate: (date) => date?.format(),
    endDate: (date) => date?.format(),
    user: (user) => (user ? (typeof user === "string" ? user : user.id) : null),
    leaveType: (leaveType) => leaveType?.id,
    eventType: (eventType) => eventType?.id,
    location: (location) => (location ? location.id : null),
    job: (job) => (job ? job.id : null),
    notes: (notes) => (submittedData.eventType?.hasNotes ? notes : ""),
    breaks: (breaks) =>
      breaks.sort(({ start: pStart }, { start: nStart }) => pStart - nStart),
  });

// drag
export const dragTypes = {
  EVENT: "EVENT",
  BREAK_SLIDER: "BREAK_SLIDER",
  EVENT_TIME_SLIDER: "EVENT_TIME_SLIDER",
  RESIZE_HANDLE: "RESIZE_HANDLE",
  EVENT_PAINTER: "EVENT_PAINTER",
};

export const snapToGrid = (parentX, parentY) => {
  const snapAmount = { x: 5, y: 70 };

  const x = Math.round(parentX / snapAmount.x) * snapAmount.x;
  const y = parentY && Math.round(parentY / snapAmount.y) * snapAmount.y;
  return parentY ? { x, y } : x;
};

// deselect
export const useDeselectEvents = (ref, targets = []) => {
  const dispatch = useDispatch();
  const selected = useSelector(
    ({ schedule: { selectedEvents } }) => selectedEvents.length > 0
  );

  const onClick = useCallback(
    (e) => {
      if (!selected) return;

      const allTargets = [".sch-event", ...targets];
      allTargets.every((t) => !e.target.closest(t)) &&
        dispatch({ type: "REQUEST_DESELECT_ALL_EVENTS" });
    },
    [targets, dispatch, selected]
  );

  useEffect(() => {
    const current = ref.current;
    current.addEventListener("click", onClick);
    return () => current.removeEventListener("click", onClick);
  }, [ref, onClick]);
};

// helpers for time
export const calculateDragTime = ({
  offset,
  MINUTE_WIDTH,
  scrollLeft,
  scrollerX,
}) => {
  const minutesDragged = getMinutesFromOffset(
    offset - scrollerX + scrollLeft,
    MINUTE_WIDTH
  );

  let { h, m } = getHoursAndMinutes(roundMinutesBy5(minutesDragged));

  return { h, m };
};

export const getMinutesFromOffset = (offset, MINUTE_WIDTH) =>
  Math.round(offset / MINUTE_WIDTH);

export const roundMinutesBy5 = (minutes) =>
  Math.floor(minutes / 60) * 60 +
  (minutes % 60) +
  (minutes % 5 > 2.5 ? 5 - (minutes % 5) : -minutes % 5);

export const getHoursAndMinutes = (minutes) => {
  const h = Math.floor(minutes / 60);

  return {
    h,
    m: minutes % 60,
    isAM: h < 13,
  };
};

export const getFormattedTime = ({ h: parentH, m }, withMeridiem = false) => {
  const h = withMeridiem ? (parentH > 12 ? parentH - 12 : parentH) : parentH;

  return `${h < 10 ? 0 : ""}${h}:${m < 10 ? 0 : ""}${m}`;
};

const isTimeValid = ({ h, m }) => h >= 0 && h <= 24 && m >= 0 && m < 60;

export const getIsLeave = (event) => event.typeName === leaveEventTypeName;

export const is2DayEvent = (event) => {
  return moment(event.endDate).isAfter(moment(event.startDate), "date");
};

export const get2DayEventAttributes = (event, date) => {
  return {
    is2Day: !getIsLeave(event) && is2DayEvent(event),
    isSecondPartEvent:
      !getIsLeave(event) && moment(event.endDate).isSame(date, "day"),
  };
};

/**
 * mappers
 */
export const getDateKey = (date = "") => moment(date).format("YYYY-MM-DD");

const getMappingKeys = (event, secondPart = false) => ({
  dateKey: getDateKey(event[secondPart ? "endDate" : "startDate"]),
  userKey: event.user.id,
});

const getEventJobOrLocation = (entity, userEntities) =>
  typeof entity === "string"
    ? userEntities.find(({ id }) => id === entity) || {}
    : entity;

export const formatEvent = ({
  breaks = [],
  location,
  job,
  eventType,
  user,
  leaveId,
  id,
  ...rest
}) => ({
  ...rest,
  id: eventType?.name === leaveEventTypeName ? leaveId : id,
  leaveId,
  breaks: Array.isArray(breaks) ? breaks : JSON.parse(breaks) || [],
  typeColor: eventType?.color,
  typeName: eventType?.name,
  eventType,
  user,
  location: getEventJobOrLocation(location, user?.locations),
  job: getEventJobOrLocation(job, user?.jobs),
  userName: getUserDisplayName(user),
});

export const mapChangedEventByView = ({
  state,
  view,
  oldEvent,
  newEvent,
  changedPreviously = {},
}) => {
  let { userEvents, dateEvents, monthEvents, selectedDate, mode } = state;

  const viewToIterate = view || mode;

  switch (viewToIterate) {
    case schedulerModes.WEEK:
    case schedulerModes.DAY: {
      const changed = {
        ...changedPreviously,
        userEvents: {
          ...userEvents,
          events: mapEvents(
            userEvents.events,
            { remove: oldEvent, add: newEvent },
            true
          ),
        },
      };

      if (selectedDate) {
        changed.monthEvents = {
          ...monthEvents,
          events: mapEvents(monthEvents.events, {
            remove: oldEvent,
            add: newEvent,
          }),
        };
      }

      return changed;
    }

    case schedulerModes.MONTH: {
      const changed = {
        ...changedPreviously,
        monthEvents: {
          ...monthEvents,
          events: mapEvents(monthEvents.events, {
            remove: oldEvent,
            add: newEvent,
          }),
        },
      };

      if (selectedDate) {
        changed.userEvents = {
          ...userEvents,
          events: mapEvents(
            userEvents.events,
            { remove: oldEvent, add: newEvent },
            true
          ),
        };
      }

      return changed;
    }

    case schedulerModes.EXTRA_DAY: {
      return {
        ...changedPreviously,
        dateEvents: {
          ...dateEvents,
          events: remapDateEvents(dateEvents.events, {
            remove: oldEvent,
            add: newEvent,
          }),
        },
      };
    }

    default:
      break;
  }
};

//  events
export const initializeMonthEvents = (dates) =>
  dates.reduce((mapping, day) => set(mapping, getDateKey(day), []), {});

export const initializeUserEvents = (users, dates) =>
  users.reduce((userEvents, { id: userKey }) => {
    userEvents[userKey] = dates.reduce(
      (userRow, date) => set(userRow, [getDateKey(date)], []),
      {}
    );

    return userEvents;
  }, {});

const setInEventMapping = (
  newMapping = {},
  events,
  shouldRemove = false,
  mapByUser = false
) => {
  if (Array.isArray(events)) {
    events.forEach((event) =>
      setInEventMapping(newMapping, event, shouldRemove, mapByUser)
    );
  } else {
    const event = formatEvent(events);

    const set = ({ secondPart = false, eventOverride }) => {
      const eventToSet = eventOverride || event;
      const { userKey, dateKey } = getMappingKeys(eventToSet, secondPart);

      update(
        newMapping,
        mapByUser ? [userKey, dateKey] : [dateKey],
        (value) => {
          if (shouldRemove && !!value)
            value = value.filter(({ id }) => eventToSet.id !== id);
          else value = value ? [...value, eventToSet] : [eventToSet];

          return value;
        }
      );
    };

    if (event.isLeave) {
      const iterator = moment(event.leaveStartDate);

      while (iterator.isSameOrBefore(event.leaveEndDate)) {
        set({
          eventOverride: {
            ...event,
            startDate: moment(iterator).startOf("day"),
            endDate: moment(iterator).endOf("day"),
          },
        });
        iterator.add(1, "day");
      }
    } else {
      set({});
      if (is2DayEvent(event)) set({ secondPart: true });
    }
  }

  return newMapping;
};

export const mapEvents = (
  oldMapping,
  { remove: eventsToRemove, add: eventsToAdd },
  mapByUser = false
) => {
  const newMapping = mapByUser ? cloneDeep(oldMapping) : { ...oldMapping };
  eventsToRemove &&
    setInEventMapping(newMapping, eventsToRemove, true, mapByUser);
  eventsToAdd && setInEventMapping(newMapping, eventsToAdd, false, mapByUser);

  return newMapping;
};

// date events
export const remapDateEvents = (
  oldDateEvents,
  { remove: eventsToRemove, add: eventsToAdd }
) => {
  let newDateEvents = [...oldDateEvents];

  eventsToRemove &&
    newDateEvents.filter(({ id }) =>
      Array.isArray(eventsToRemove)
        ? eventsToRemove.find(({ id: idToRemove }) => idToRemove === id)
        : id !== eventsToRemove.id
    );

  if (eventsToAdd)
    if (Array.isArray(eventsToAdd))
      newDateEvents = newDateEvents.concat(eventsToAdd.map(formatEvent));
    else newDateEvents.push(formatEvent(eventsToAdd));

  return newDateEvents;
};
