import { createReducer, createActions } from "reduxsauce";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";

const initialState = {
  ...Pagination("leaveTypes").model(),
  ...CRUD("leaveTypes").model(),
};

const { Types: types, Creators: actions } = createActions({
  ...Pagination("leaveTypes").actions(),
  ...CRUD("leaveTypes").actions(),
});

export default createReducer(initialState, {
  ...Pagination("leaveTypes").reducer(),
  ...CRUD("leaveTypes").reducer(),
});

export { types, actions };
