import { mapChangedEventByView } from "../../../helpers/Events";
import { addEventsToList, removeEventsFromList } from "./events";

export const actions = {
  // creating
  requestEventPanel: ["eventPanel"],
  setEventPanelError: ["error"],

  // submit
  requestSubmitEvent: ["data"],
  submitEventSucceeded: null,
  submitEventFailed: ["error"],
  // delete
  requestDeleteEvent: ["event"],
  deleteEventSucceeded: ["event"],
  deleteEventFailed: ["error"],
  // actions (approve, release, submit, reject, publish)
  requestActionOnEvent: ["event", "action"],
  actionOnEventSucceeded: ["newEvent"],
  actionOnEventFailed: ["error"],

  // batch actions
  requestBatchActionOnEvents: ["events", "action", "options"],
  batchActionOnEventsSucceeded: null,
  batchActionOnEventsFailed: null,
};

export default (types) => ({
  [types.REQUEST_EVENT_PANEL]: (state, { eventPanel }) => ({
    ...state,
    eventPanel,
  }),
  [types.SET_EVENT_PANEL_ERROR]: ({ eventPanel, ...state }, { error }) => ({
    ...state,
    eventPanel: {
      ...eventPanel,
      error,
    },
  }),

  // create
  [types.REQUEST_SUBMIT_EVENT]: ({ eventPanel, ...state }) => ({
    ...state,
    eventPanel: {
      ...eventPanel,
      submitting: true,
      error: undefined,
    },
  }),
  [types.SUBMIT_EVENT_SUCCEEDED]: (
    { eventPanel: { event: oldEvent }, ...state },
    { newEvent }
  ) => ({
    ...state,
    ...mapChangedEventByView({
      state,
      view: state.mode,
      oldEvent,
      newEvent,
    }),
    eventPanel: {
      open: false,
      submitting: false,
      error: undefined,
    },
  }),
  [types.SUBMIT_EVENT_FAILED]: ({ eventPanel, ...state }, { error }) => ({
    ...state,
    eventPanel: {
      ...eventPanel,
      submitting: false,
      error,
    },
  }),
  // delete
  [types.REQUEST_DELETE_EVENT]: ({ ...state }) => ({
    ...state,
  }),
  [types.DELETE_EVENT_SUCCEEDED]: (state, { event }) => ({
    ...state,
    ...mapChangedEventByView({
      state,
      view: state.mode,
      oldEvent: event,
    }),
  }),
  // actions
  [types.REQUEST_ACTION_ON_EVENT]: ({ ...state }) => ({
    ...state,
  }),
  [types.ACTION_ON_EVENT_SUCCEEDED]: (state, { newEvent }) => ({
    ...state,
    ...mapChangedEventByView({ state, newEvent, oldEvent: newEvent }),
  }),

  // batch actions
  [types.REQUEST_BATCH_ACTION_ON_EVENTS]: (
    { loadingEvents, ...state },
    { events, action }
  ) => ({
    ...state,
    loadingEvents: addEventsToList(loadingEvents, events),
  }),
  [types.BATCH_ACTION_ON_EVENTS_SUCCEEDED]: (
    { loadingEvents, selectedEvents, ...state },
    { events, updatedEvents, oldEvents, action }
  ) => ({
    ...state,
    loadingEvents: removeEventsFromList(loadingEvents, events),
    selectedEvents: removeEventsFromList(selectedEvents, events),
    ...mapChangedEventByView({
      state,
      newEvent: updatedEvents,
      oldEvent: oldEvents,
    }),
  }),
  [types.BATCH_ACTION_ON_EVENTS_FAILED]: (
    { loadingEvents, ...state },
    { events, error, action }
  ) => ({
    ...state,
    loadingEvents: removeEventsFromList(loadingEvents, events),
    error,
  }),
});
