import { takeEvery, put, call } from "redux-saga/effects";
import { types } from "../reducers/profile";
import ProfileService from "../../services/ProfileService";

export default function* () {
  yield takeEvery(
    types.REQUEST_CHANGE_PROFILE_PICTURE,
    requestChangeProfilePicture
  );
}

function* requestChangeProfilePicture({ image }) {
  try {
    const res = yield call(ProfileService.requestChangeProfilePicture, {
      image,
    });

    console.log(res);

    yield put({
      type: types.CHANGE_PROFILE_PICTURE_SUCCEEDED,
    });
  } catch (error) {
    yield put({
      type: types.CHANGE_PROFILE_PICTURE_FAILED,
      error,
    });
  }
}
