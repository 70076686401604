import { takeEvery } from "redux-saga/effects";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";
import PayGroupService from "../../services/PayGroupService";

export default function* () {
  yield takeEvery(
    Pagination("payGroups").types().request,
    Pagination("payGroups").saga(PayGroupService.getAll, [
      "code",
      "description",
    ])
  );
  yield takeEvery(
    CRUD("payGroups").types().submit.request,
    CRUD("payGroups").saga(PayGroupService).submit({
      status: (value) => Boolean(value),
    })
  );
  yield takeEvery(
    CRUD("payGroups").types().delete.request,
    CRUD("payGroups").saga(PayGroupService).delete()
  );
}
