import { takeEvery, put, call, select } from "redux-saga/effects";
import { types } from "../reducers/people";
import { types as authTypes } from "../reducers/authentication";
import UserService from "../../services/UserService";
import { toast } from "react-toastify";
import moment from "moment";
import AuthService from "../../services/AuthService";
import Pagination from "../../helpers/Pagination";
import { mapReceivedJobsAndLocations } from "../../helpers/Users";

const mapFinalValue = (field, value) => {
  switch (field) {
    case "birthDate":
    case "hiringDate":
      return value ? moment(value).toISOString() : value;

    case "level1":
    case "level2":
    case "level3":
    case "level4":
    case "employeeType":
    case "payGroup":
    case "userRole":
      return value ? value.id : undefined;

    case "supervisor":
      return value && Array.isArray(value) ? value.map(({ id }) => id) : [];

    case "locations":
      return value && Array.isArray(value)
        ? value.map(({ id, primary = false }) => ({ id, primary }))
        : [];

    case "jobs":
      return value && Array.isArray(value)
        ? value.map(({ id, primary = false, effectiveDate, hourlyRate }) => ({
            id,
            primary,
            effectiveDate,
            hourlyRate: parseFloat(hourlyRate),
          }))
        : [];

    default:
      return value;
  }
};

const excludeField = (field, isEdit) => {
  switch (field) {
    case "username":
      return isEdit;

    case "confirmSsn":
      return true;

    default:
      return false;
  }
};

export default function* () {
  yield takeEvery(
    Pagination("people").types().request,
    Pagination("people").saga(
      UserService.getAll,
      ["username", "firstName", "lastName", "middleName"],
      {
        locations: mapReceivedJobsAndLocations,
        jobs: mapReceivedJobsAndLocations,
      }
    )
  );
  yield takeEvery(types.REQUEST_SUBMIT_USER, requestSubmitUser);
  yield takeEvery(types.REQUEST_DELETE_USER, requestDeleteUser);
  yield takeEvery(types.REQUEST_VALIDATE_STEP, requestValidateStep);
}

function* requestSubmitUser(submittedStepValues) {
  const { data, isEdit, id, isProfile, authenticatedUserId } = yield select(
    ({
      people: {
        form: { values, active, isProfile, id },
      },
      authentication: {
        user: {
          id: authenticatedUserId,
          company: { id: company },
        },
      },
    }) => ({
      id,
      authenticatedUserId,
      isEdit: !!id,
      isProfile,
      values,
      data: Object.values(values).reduce(
        (mappedValues, currentStep, index) => {
          const stepValuesToMap =
            index === active ? submittedStepValues : currentStep;

          if (index === 1) {
            const {
              address,
              addressLine2,
              city,
              stateOrProvince,
              zipOrPostalCode,
              ...restOfTheFields
            } = stepValuesToMap;

            mappedValues = {
              ...mappedValues,
              ...restOfTheFields,
              address: {
                address,
                addressLine2,
                city,
                stateOrProvince,
                zipOrPostalCode,
              },
            };

            return mappedValues;
          }

          const mappedStep = Object.keys(stepValuesToMap).reduce(
            (mappedStep, field) =>
              excludeField(field, !!id)
                ? mappedStep
                : Object.assign(mappedStep, {
                    [field]: mapFinalValue(field, stepValuesToMap[field]),
                  }),
            {}
          );

          return Object.assign(mappedValues, mappedStep);
        },
        { company, enabled: 1 }
      ),
    })
  );

  try {
    const res = isEdit
      ? isProfile
        ? yield call(AuthService.editProfile, data)
        : yield call(UserService.updateOne, id, data)
      : yield call(UserService.create, data);

    const user = res.data;

    const formatedUser = {
      ...user,
      locations: mapReceivedJobsAndLocations(user.locations),
      jobs: mapReceivedJobsAndLocations(user.jobs),
    };

    if (user) {
      yield put({
        type: isProfile
          ? authTypes.EDIT_ME_SUCCEEDED
          : types.SUBMIT_USER_SUCCEEDED,
        user: formatedUser,
        id,
        isProfile,
      });

      if (authenticatedUserId === id)
        yield put({
          type: authTypes.EDIT_ME_SUCCEEDED,
          user: formatedUser,
        });

      yield put({
        type: types.REQUEST_RESET_FORM,
      });

      toast.success(
        isProfile
          ? "Your profile has been updated successfully"
          : `The user has been ${id ? "updated" : "created"} successfully`
      );
    }
  } catch (e) {
    console.log(e);

    let error = null;
    if (e.validation) error = e.validation[0];
    else toast.error(e.message);

    yield put({
      type: types.SUBMIT_USER_FAILED,
      error,
    });
  }
}

function* requestDeleteUser({ index }) {
  const id = yield select(({ people: { people } }) => people[index].id);

  try {
    const res = yield call(UserService.deleteOne, id);

    console.log(res);
    // if (res.data) {
    yield put({
      type: types.DELETE_USER_SUCCEEDED,
      id,
    });
    // }
  } catch (e) {
    yield put({
      type: types.DELETE_USER_FAILED,
      error: e.message,
    });
  }
}

function* requestValidateStep({ nextStep, values, shouldSubmit }) {
  const { active, id } = yield select(
    ({
      people: {
        form: { active, id },
      },
    }) => ({
      active,
      id,
    })
  );

  try {
    yield call(UserService.validateStep, id, active, values);

    if (shouldSubmit) {
      yield requestSubmitUser(values);
    } else
      yield put({
        type: types.VALIDATE_STEP_SUCCEEDED,
        nextStep,
        values,
      });
  } catch (e) {
    console.log(e);

    yield put({
      type: types.VALIDATE_STEP_FAILED,
      error: e.validation ? e.validation[0] : e,
    });
  }
}
