import CRUDService from "./CRUDService";

class UserService extends CRUDService {
  entity = "users";
  withFilters = true;

  getAllUsers = () => {
    return this.apiGet({ url: "/users" });
  };

  validateStep = async (id, step, data) => {
    const isPut = id !== null;
    const method = isPut ? "apiPut" : "apiPost";
    const extraId = isPut ? "/" + id : "";
    const stepKey = `step${step + 1}`;

    return this[method]({
      url: "/users/validate-steps" + extraId,
      data: {
        [stepKey]: data,
      },
    });
  };

  checkUsername = async (username) =>
    this.apiGet({
      url: "/users/validate-username/" + username,
    });

  getUserLocationsAndJobs = async (userId) =>
    this.apiGet({
      url: "/users/jobs-and-locations/" + userId,
    });

  shareEmployee = async (id, data) =>
    this.apiPost({
      url: `/users/${id}/share-dummy-url`,
      data,
    });

  getWithEvents = (filters) =>
    this.apiGetWithFilters({ url: "/users/events" }, filters);
}

export default new UserService();
