import React, { useRef, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import Button from "../Button";
import clsx from "clsx";
import { useKeyPress } from "../../helpers/General";

const clipDistance = 64;

function Modal({
  children,
  className,
  open = true,
  overlay = true,
  sticky = false,
  elementId,
  close = () => {},
  closeOnOuterClick = true,
  noPadding = false,
  withCloseButton = true,
  enterAnimation = "",
}) {
  const ref = useRef();
  const indicatorRef = useRef();

  const clickOutsideHandler = useCallback(
    (e) => {
      !e.target.closest(".modal") &&
        !e.target.closest(`#${elementId}`) &&
        close();
    },
    [elementId, close]
  );

  useEffect(() => {
    if (sticky) {
      const el = document.getElementById(elementId);

      if (!el || !ref.current) return;

      const heightThreshold = window.innerHeight;
      const { width, height } = ref.current.getBoundingClientRect();
      const {
        x: elementX,
        y: elementY,
        height: elementHeight,
      } = el.getBoundingClientRect();

      let x = elementX,
        y = elementY;

      if (x - width < clipDistance) x = width + clipDistance;
      if (y + height > heightThreshold - clipDistance / 2)
        y = heightThreshold - height - clipDistance / 2;

      let indicatorY = elementY - y + elementHeight / 2;
      indicatorY =
        indicatorY > heightThreshold - clipDistance - y
          ? heightThreshold - clipDistance - y
          : indicatorY;

      ref.current.style.transform = `translate(${x}px,${y}px) translateX(-100%) translateX(-${
        clipDistance / 4
      }px)`;
      indicatorRef.current.style.transform = `translate(55%, ${indicatorY}px) translateY(-50%) rotate(45deg) `;

      document.addEventListener("click", clickOutsideHandler);
    }
    return () => document.removeEventListener("click", clickOutsideHandler);
  }, [open, elementId, clickOutsideHandler, sticky]);

  useKeyPress("Escape", closeOnOuterClick ? close : undefined);

  const modal = (
    <div
      ref={ref}
      className={clsx(
        "modal",
        sticky && "sticky",
        noPadding && "no-padding",
        enterAnimation,
        className
      )}
    >
      {!sticky && withCloseButton && (
        <Button icon="times" size="sm" onClick={close} className="close-cta" />
      )}
      {children}
      {sticky && <span ref={indicatorRef} className="indicator" />}
    </div>
  );

  const withOverlay = (
    <div className="modal-wrapper">
      {overlay && !sticky && (
        <div className="overlay" onClick={() => closeOnOuterClick && close()} />
      )}
      {modal}
    </div>
  );

  return open
    ? ReactDOM.createPortal(
        sticky ? modal : withOverlay,
        document.getElementById("modal-portal")
      )
    : null;
}

export const ModalPortal = () => <div id="modal-portal" />;

export default Modal;
