import CRUDService from "./CRUDService";

class MessageService extends CRUDService {
  withFilters = true;
  entity = "messages";

  getAll = async (roomId) =>
    this.apiGet({ url: `room/${roomId}/messages` });

  create = async ({ conversationId, message }) => {
    const { body, attachments } = message;

    const data = new FormData();
    data.append("body", body);
    data.append("attachments", attachments.map((f) => f.data));

    return this.apiPostWithFormData({
      url: `room/${conversationId}/messages`,
      data,
    });
  };
}

export default new MessageService();
