export const paginationStartIndex = 1;
export const paginationLimit = 20;

export const leaveEventTypeName = "Leave";

export const schedulerModes = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  EXTRA_DAY: "EXTRA_DAY",
};

export const eventTypes = [
  "type-1",
  "type-2",
  "type-3",
  "type-4",
  "day-off",
  "rejected",
  "pending",
  "unavailability",
];

export const hardAuth = false;
// export const hardAuth = true;
