import initialState from "../models/posts";
import { createReducer, createActions } from "reduxsauce";
import Pagination from "../../helpers/Pagination";
import CRUD from "../../helpers/CRUD";

const { Types: types, Creators: actions } = createActions({
  ...Pagination("posts").actions(),
  ...CRUD("posts").actions(),
  requestLikePost: ["id", "liked"],
  likePostSucceeded: null,
  likePostFailed: null,
});

function getUpdatedCommentedPost(post, isCommentAdded = false) {
  let commentsCount = post.commentsCount;

  if (commentsCount === undefined) {
    if (isCommentAdded) commentsCount = 1;
    else commentsCount = 0;
  } else {
    if (isCommentAdded) commentsCount += 1;
    else commentsCount -= 1;
  }

  return {
    ...post,
    commentsCount,
  };
}

export function getUpdatedLikedPost(post) {
  const wasPostLiked = post.liked;
  let likesCount = post.likesCount;

  if (post.likesCount === undefined) {
    if (wasPostLiked) likesCount = 0;
    else likesCount = 1;
  } else {
    if (wasPostLiked) likesCount -= 1;
    else likesCount += 1;
  }

  return {
    ...post,
    liked: !post.liked,
    likesCount,
  };
};

export default createReducer(initialState, {
  ...Pagination("posts").reducer(),
  ...CRUD("posts").reducer(),
  [types.LIKE_POST_SUCCEEDED]: ({ posts, ...state }, { id }) => ({
    ...state,
    posts: posts.map((post) => post.id === id 
      ? getUpdatedLikedPost(post) 
      : post,
    ),
  }),
  [types.LIKE_POST_FAILED]: (state, { error }) => ({ ...state, error }),

  // overwrites
  [types.DELETE_POSTS_SUCCEEDED]: ({ posts, ...state }, { index }) => ({
    ...state,
    loading: false,
    error: null,
    total: state.total - 1,
    posts: posts.filter((_, i) => i !== index),
  }),
  [types.SUBMIT_POSTS_SUCCEEDED]: ({ posts, ...state }, { newEntity }) => ({
    ...state,
    submitting: false,
    error: null,
    total: state.total + 1,
    posts: [newEntity, ...posts],
  }), 

  // changes based on comments chagnes
  COMMENT_ON_POST_SUCCEEDED: ({ posts, ...state }, { postId }) => ({
    ...state,
    posts: posts.map((post) => post.id === postId 
      ? getUpdatedCommentedPost(post, true)
      : post,
    ),
  }),
  DELETE_COMMENT_SUCCEEDED: ({ posts, ...state }, { postId }) => ({
    ...state,
    posts: posts.map((post) => post.id === postId 
      ? getUpdatedCommentedPost(post, false) 
      : post,
    ),
  }),
});

export { types, actions };

