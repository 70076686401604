import {
  takeEvery,
  put,
  delay,
  // call
} from "redux-saga/effects";
// import Axios from "axios";
import { types } from "../reducers/forms";

export default function* () {
  yield takeEvery(types.REQUEST_SUBMIT, requestSubmit);
}

function* requestSubmit({ value: { form, type } }) {
  try {
    //  TODO: perform call
    // const res = yield call(
    //   Axios.get,
    //   "route"
    // );

    // const paginated = res.data.data;

    yield delay();

    yield put({
      type: types.SUBMIT_Succeeded,
      value: { form },
    });
  } catch (e) {
    yield put({
      type: types.SUBMIT_FAILED,
      value: { form, validationErrors: { name: "Fuck no" } },
    });
  }
}
