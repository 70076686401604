import React from "react";
import Icon from "../Icon";

export default function Loading() {
  return (
    <div className="loading-indicator">
      <Icon icon="spinner" style={{ marginRight: 0 }} spin />
    </div>
  );
}
